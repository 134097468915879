.DraggableTag {

    position: relative;
    ul {
        padding-left: 5px;
    }

    &__dragtag {
        z-index: 1000000;
        list-style-type: none;
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;

        >div {
            display: flex;
            align-items: center;
        }

        >span {
            cursor: pointer;
            margin-left: auto;
            display: flex;
            align-items: center;

            >span {
                margin-right: 10px;
            }
        }

        &__tag {
            cursor: pointer;
        }
    }

    &__draghandle {
        cursor: pointer;
        margin-right: 15px;
    }



}