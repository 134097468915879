.ListCannedTemplate {
    display: flex;
    flex-direction: column;
    flex: 1;

    &__EditIcon:hover {
        background: #F0F0F0;
    }

    &__EditIcon {
        border-radius: 2px;
        background: #FAFAFA;
        padding: 4px 8px;
        border: 0px;
        width: 28px;
        height: 28px;
        border-radius: 4px;
        cursor: pointer;



        &__Color {}
    }

    &__activity_buttons {
        display: flex;
        justify-content: space-between;
        margin-bottom: 35px;

        &__searchbar {
            width: 181px;
        }

        &__button {
            width: 172px;
        }
    }

    &__header {
        display: flex;
        flex-direction: column;
        border-bottom: 1px solid #f0f0f0;

        h3 {
            margin-bottom: 4px;
            font-weight: 600;
            font-size: 20px;
            line-height: 28px;
            font-family: "Inter";
            font-style: normal;
        }

        p {
            margin-top: 0;
            margin-bottom: 30px;

            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
            letter-spacing: 0.0025em;

            color: #424242;
        }
    }

    &__Body {
        display: flex;
        width: 100%;
        height: 100%;
        gap: 9px;
        overflow: auto;
        padding-top: 20px;
        position: relative;

        &__PaddingRight {
            padding-right: 472px;
        }

        &__DisabledOverlay {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(255, 255, 255, 0.5);
            z-index: 10;
          }

        &__Table {
            flex: 1;
            background: #FFFFFF;
            border: 1px solid #D9D9D9;
            border-radius: 8px;
            padding: 22px 42px;
            gap: 36px;
            overflow: auto;
        }

        &__Preview {

            display: flex;
            flex-direction: column;
            width: 463px;
            padding: 22px 40px;
            gap: 8px;
            height: 100%;
            background: #FFFFFF;
            border: 1px solid #D9D9D9;
            border-radius: 8px;
            gap: 24px;
        }
    }

    &__delete:hover {
        background: #FFCCC7;
    }

    &__delete {
        border-radius: 2px;
        background-color: #fff1f0;
        padding: 4px 8px;
        border: 0px;
        width: 28px;
        height: 28px;
        border-radius: 4px;
        cursor: pointer;



        svg {
            fill: #f5222d;
        }

    }

    &__eye {
        border-radius: 2px;
        background-color: #FAFAFA;
        padding: 4px 8px;
        height: 29px;
        padding-top: 8px;

        &__Color {
            background: #E6F7FF;
        }
    }

    &__PreviewContent {
        height: 100%;


        &__Title {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 24px;
            color: #262626;
        }

        &__Preview {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding: 10px;
            gap: 10px;
            white-space: pre-line;
            background: #E6F7FF;
            border-radius: 4px;
        }

        &__Header {
            display: flex;
            justify-content: space-between;
            width: 100%;
            border-bottom: 1px solid #F0F0F0;

            p {
                font-weight: 500;
                font-size: 16px;
                line-height: 28px;
                color: #262626;
            }
        }
    }

}

@media (max-width:768px){
    .ListCannedTemplate {
        display: flex;
        flex-direction: column;
        flex: 1;
    
        &__EditIcon:hover {
            background: #F0F0F0;
        }
    
        &__EditIcon {
            border-radius: 2px;
            background: #FAFAFA;
            padding: 4px 8px;
            border: 0px;
            width: 28px;
            height: 28px;
            border-radius: 4px;
            cursor: pointer;
    
    
    
            &__Color {}
        }
    
        &__activity_buttons {
            display: flex;
            justify-content: space-between;
            margin-bottom: 35px;
    
            &__searchbar {
                width: 181px;
            }
    
            &__button {
                width: 172px;
            }
        }
    
        &__header {
            display: flex;
            flex-direction: column;
            border-bottom: 1px solid #f0f0f0;
    
            h3 {
                margin-bottom: 4px;
                font-weight: 600;
                font-size: 20px;
                line-height: 28px;
                font-family: "Inter";
                font-style: normal;
            }
    
            p {
                margin-top: 0;
                margin-bottom: 30px;
    
                font-weight: 400;
                font-size: 16px;
                line-height: 19px;
                letter-spacing: 0.0025em;
    
                color: #424242;
            }
        }
    
        &__Body {
            display: flex;
            width: 100%;
            height: 100%;
            gap: 9px;
            overflow: auto;
            padding-top: 20px;
    
            &__PaddingRight {
                padding-right: 0px;
            }
    
            &__Table {
                flex: 1;
                background: #FFFFFF;
                border: 1px solid #D9D9D9;
                border-radius: 8px;
                padding: 10px 5px;
                gap: 36px;
                overflow: auto;
            }
    
            &__Preview {
    
                display: flex;
                flex-direction: column;
                width: 463px;
                padding: 22px 40px;
                gap: 8px;
                height: 100%;
                background: #FFFFFF;
                border: 1px solid #D9D9D9;
                border-radius: 8px;
                gap: 24px;
            }
        }
    
        &__delete:hover {
            background: #FFCCC7;
        }
    
        &__delete {
            border-radius: 2px;
            background-color: #fff1f0;
            padding: 4px 8px;
            border: 0px;
            width: 28px;
            height: 28px;
            border-radius: 4px;
            cursor: pointer;
    
    
    
            svg {
                fill: #f5222d;
            }
    
        }
    
        &__eye {
            border-radius: 2px;
            background-color: #FAFAFA;
            padding: 4px 8px;
            height: 29px;
            padding-top: 8px;
    
            &__Color {
                background: #E6F7FF;
            }
        }
    
        &__PreviewContent {
            height: 100%;
    
    
            &__Title {
                font-family: 'Inter';
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                line-height: 24px;
                color: #262626;
            }
    
            &__Preview {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                padding: 10px;
                gap: 10px;
                white-space: pre-line;
                background: #E6F7FF;
                border-radius: 4px;
            }
    
            &__Header {
                display: flex;
                justify-content: space-between;
                width: 100%;
                border-bottom: 1px solid #F0F0F0;
    
                p {
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 28px;
                    color: #262626;
                }
            }
        }
    
    }
    
}