.Signup {
  width: 100%;

  &__InputLabel {
    color: #616161;
  }

  &__signUpContainer {
    width: 85%;
    margin: 0 auto;

    h3 {
      text-align: left;
      margin-bottom: 10px;
      margin-top: 30px;
      padding: 20px 0px 0px 0px;
      font-size: 25px;
      font-weight: 500;
    }

    &__formItem {
      display: flex !important;
      flex-direction: column !important;

      &__error {
        color: red;
        height: 0;
        font-size: 12px !important;
      }

      &__signUpBtnContainer {
        margin: 0 auto;
        width: 100%;
        text-align: center;
        margin-top: 20px;
        margin-bottom: 20px;

        Button {
          min-width: 256px;
          width: 100%;
          height: 40px;
          color: white;
          border-radius: 3px;

          span {
            font-size: 16px;
            font-weight: 400;
          }
        }
      }
    }

    &__errorMsg {
      text-transform: capitalize;
      margin-bottom: 20px;
    }

    &__helpMsg {
      font-size: 14px;
      font-weight: 400;
      color: #a9a9a9;
      padding-bottom: 32px;

      a {
        cursor: pointer;
      }
    }
  }

  &__footer {
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    padding-top: 0px;
    padding-bottom: 10px;
    padding-bottom: 65px;

    span {
      color: #706e6e;
    }
  }
}

@media (max-width: 1020px) {
  .Signup {
    &__signUpContainer {
      &__formItem {
        &__error {
          font-size: 11px !important;
        }
      }
    }
  }
}

@media (max-width: 980px) {
  .Signup {
    padding: 25px;

    &__signUpContainer {
      width: 85%;

      h3 {
        color: rgba(89, 126, 247, 1);
        margin-top: 0;
        font-size: 21px;
      }

      &__name {
        display: block;
        width: 100%;
        margin-left: 0 !important;
        margin-right: 0 !important;

        input {
          width: 100%;
        }

        :global {
          .ant-col-12 {
            padding-left: 0 !important;
            padding-right: 0 !important;
            max-width: 100%;
          }
        }
      }

      &__formItem {
        &__signUpBtnContainer {
          Button {
            min-width: 151px;
          }
        }
      }
    }
  }
}

@media (max-width: 575px) {
  .Signup {
    padding: 10px;

    &__signUpContainer {
      width: 95%;

      &__formItem {
        :global {
          .ant-form-item-label {
            padding: 0;
          }
        }
      }
    }
  }
}
