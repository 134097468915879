.AuthLayout {
  width: 100%;
  padding: 80px 20px;
  .__formContainer {
    width: 65%;
    margin: 0 auto;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
    border-radius: 8px;
  }
}

@media (max-width: 980px) {
  .AuthLayout {
    padding: 0px;
    .__formContainer {
      width: 100%;
      height: 100vh;
      margin: 0;
      border-radius: 0;
      box-shadow: none;
      .__fullsignup {
        display: block !important;
        width: 100%;

        .__carousel {
          height: 100vh;
          max-width: 100% !important;
        }

        :global {
          .ant-col-12 {
            max-width: 100% !important;
          }
        }
      }
    }
  }
}
