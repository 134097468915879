.CreateCannedUploadRow {}

.DraggableTag {
    &__CloseIcon {
        margin-left: auto;
        padding: 4px;
    }

    ul {
        padding-left: 0px;
    }

    &__dragtag {
        z-index: 1000000;
        list-style-type: none;
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 4px;
        gap: 16px;
        width: 330px;
        height: 32px;
        background: #F5F5F5;
        border-radius: 4px;
        width: 100%;

        >div {
            display: flex;
            align-items: center;
            width: 100%;

            p {
                overflow: hidden;
                max-width: 380px;
                line-height: 32px;
                margin-bottom: 0px;
                white-space: nowrap;
            }


        }

        >span {
            cursor: pointer;
            margin-left: auto;
            display: flex;
            align-items: center;

            >span {
                margin-right: 10px;
            }
        }

        &__tag {
            cursor: pointer;
        }
    }

    &__draghandle {
        cursor: pointer;
        margin-right: 15px;
        margin-left: 12px;
        height: 100%;
    }

}