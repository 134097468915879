.FeatureCarousel {
    &__SlideImage {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;

        background: #597EF7;

        /* Elevation */

        border-radius: 0px 8px 8px 0px;

    }

    &__ImageContainer {
        display: flex;
        justify-content: space-around;
        align-items: center;
        flex: 1;
        padding: 20px;
        padding-left: 30px;
        padding-right: 30px;
        margin-bottom: 20px;

        img {
            width: 100%;
        }
    }

    &__TextContainer {
        padding-top: 40px;
        padding-left: 50px;
        padding-right: 50px;
        width: 100%;
        min-height: 0px;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        text-align: center;
        color: #FFFFFF;
    }
}