.AutomationIndividualAction {
    display: flex;
    flex-direction: column;
    &__gap{
        display: flex;
  align-items: center;
  gap: 9px;
  margin-top: 12px;
    }
    &__Body {
        display: flex;
        flex-direction: column;
        padding: 16px;
        gap: 16px;
        width: 100%;
        height: auto;

        &__FirstRow {
            display: flex;
            gap: 16px;
        }
    }

    &__message {
        margin: 16px 0px;
        display: flex;
        flex-direction: column;

        &__head {
            color: var(--gray-gray-8, #595959);

            /* Medium/14px | 22px */
            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 22px;
            /* 157.143% */
        }
        &__container{
            display: flex;
            flex-direction: column;
            &__label {
                color: var(--gray-gray-8, #595959);
    
                /* Regular/12px | 20px */
                font-family: Inter;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 20px;
                /* 166.667% */
            }
        }


    }
}