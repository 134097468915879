.PreviewGenerator {
    width: 100%;
    min-height: 0;
    flex: 1 1 0;
    overflow: auto;

    &__Message {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 10px;
        gap: 10px;
        background: #E6F7FF;
        border-radius: 4px;
        margin-bottom: 16px;
        white-space: pre-line;

        video {
            width: 100%;
        }

        audio {
            width: 100%;
        }

        img {
            width: 100%;
        }

        &__AttachmentRow {
            display: flex;
            padding: 8px;
            gap: 16px;
            width: 100%;
            height: 40px;
            background: #F5F5F5;
            border-radius: 4px;

            &__AttachmentLeft {
                width: 24px;
                height: 24px;

                span {
                    width: 24px;
                    height: 24px;
                    font-size: 24px;
                }
            }

            &__AttachmentName {
                font-family: 'Inter';
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 22px;
                color: #262626;
            }
        }
    }
}