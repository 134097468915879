.EditTag {
    &__modal{
        :global .ant-modal-close-x{
            display: none;
        }
    }
    &__picker {
        display: flex;
        flex-direction: column;
        padding: 10px;
        background: #FFFFFF;
        display: flex;
        flex-direction: column;

        // &__input {
        //     border-bottom: 1px solid #F0F0F0;
        // }

        &__delete {
            display: flex;
            align-items: center;
            padding: 12px 0px;
            border-bottom: 1px solid #F0F0F0;
            border-top: 1px solid #F0F0F0;
            margin-top: 10px;
            cursor: pointer;

            >span {
                margin-left: 10px;
            }
        }
        &__delete:hover{
            background-color: rgb(198, 222, 230);
        }

        &__picker {
            padding-top: 12px;
            display: flex;
            flex-direction: column;

            >span {
                font-weight: 400;
                font-size: 14px;
                line-height: 22px;
                color: #262626;
                margin-bottom: 8px;


            }
        }
    }
}