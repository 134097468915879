.QuerySelector {
  display: flex;
  flex-direction: column;

  &__Card {
    margin-top: 10px;
    display: flex;
    align-items: flex-start;

    // background-color: #fff8f8;
    :global .ant-card-body {
      display: flex;
    }

    :global .ant-btn.ant-btn-default.ant-btn-icon-only {
      border: none !important;
      background: none !important;
      color: #595959;
      padding-left: 17px;
    }
    :global .ant-btn[disabled] {
      border: none !important;
      background: none !important;
      color: #BFBFBF !important;
      padding-left: 17px;
    }

    &__body {
      display: flex;
      flex-wrap: wrap;
      gap: 15px;
      justify-content: flex-start;

      &__allcontainers {
        display: flex;
        flex-wrap: wrap;
        gap: 12px;
        justify-content: space-between;
        width: 100%;
      }
    }
  }

  &__filters {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 20px 0px;

    &__line {
      position: absolute;
      width: 1px;
      height: 100%;
      border-left: 2px dotted #d9d9d9;
      /* Use border instead of background-color */
      left: 50%;
      top: 0;
      transform: translateX(-50%);
      z-index: 1;
    }
  }

}