.BillingPage {
  height: calc(100% - 100px);
  width: 920px;
  &__header {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #f0f0f0;

    h3 {
      margin-bottom: 4px;
      font-weight: 600;
      font-size: 20px;
      line-height: 28px;
      font-family: "Inter";
      font-style: normal;
    }
    p {
      margin-top: 0;
      margin-bottom: 30px;

      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      letter-spacing: 0.0025em;

      color: #424242;
    }
  }

  &__body {
    // height: 100%;
    overflow-y: auto;
    margin-top: 20px;
    min-height: 372px;
    background: #ffffff;
    border: 1px solid #d9d9d9;
    border-radius: 8px;
    padding-top: 50px;
    position: relative;

    &__DisabledOverlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(255, 255, 255, 0.5);
      z-index: 10;
    }
    
  }
}

.PricingTable {
  :global {
    .ant-btn {
      background-color: #1890ff;
      border-radius: 2px;
      height: 40px;
      font-size: 16px;
      padding: 5px 16px;
      width: 160px;
    }
  }
  background-color: rgb(255, 255, 255);
  font-family: Lato, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica Neue,
    Ubuntu, sans-serif;
  height: 100%;
  opacity: 1;
  padding: 4px;
  transition: opacity 0.2s linear;
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &__tabsContainer {
    margin-left: 20px;
    margin-bottom: 65px;
    color: hsla(0, 0%, 10%, 0.9);
    font-size: 16px;
    display: flex;
    &__tabsList {
      display: flex;
      background-color: hsla(0, 0%, 10%, 0.05);
      border-color: hsla(0, 0%, 10%, 0.1);
      border: 1px solid hsla(0, 0%, 10%, 0.1);
      border-radius: 8px;
      padding: 2px;

      &__item {
        z-index: 2;
        background-color: transparent;
        border-radius: 6px;
        font-size: 14px;
        font-weight: 400;
        height: auto;
        line-height: 20px;
        padding: 8px 32px;
        cursor: pointer;
      }
    }
  }

  &__container {
    margin-bottom: 36px;
    display: grid;
    grid-gap: 16px;
    grid-template-columns: repeat(auto-fit, minmax(270px, 270px));
    justify-content: center;
    width: 100%;
    &__priceColumn {
      font-family: Lato, -apple-system, BlinkMacSystemFont, Segoe UI,
        Helvetica Neue, Ubuntu, sans-serif;
      border: 1px solid black;
      display: flex;
      width: 270px;
      padding: 16px 0px;
      color: #1a1a1a;
      border: 1px solid #d9d9d9;
      border-radius: 8px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: relative;
      &__flag {
        position: absolute;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 10px 20px;
        width: 270px;
        top: -42px;
        background: #e6f7ff;
        border: 1px solid #bae7ff;
        border-radius: 8px 8px 0px 0px;
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #096dd9;
      }
      &__header {
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 24px;
        color: #262626;
        margin-bottom: 10px;
      }

      &__content {
        width: 100%;
        margin-top: 4px;
        display: flex;
        flex-direction: column;
        align-items: center;
        &__input {
          margin-bottom: 16px;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          font-family: "Inter";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 22px;
          color: #262626;
          height: 38px;
          button {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 4px;
            gap: 10px;
            width: 24px;
            height: 26px;
            background: #ffffff;
            border: 1px solid #d9d9d9;
            border-radius: 2px;
            cursor: pointer;
          }
          &__wrapper {
            position: relative;
            margin-left: 8px;
            margin-right: 8px;
            width: 59px;
            background: #ffffff;
            border: 1px solid #d9d9d9;
            border-radius: 2px;
            height: 26px;
            input {
              height: 24px;
              border: none;
              width: 57px;
              margin: 0;
              padding-left: 32px;
            }
            img {
              position: absolute;
              bottom: 5px;
              left: 13px;
            }
          }
        }
        &__priceContainer {
          margin-bottom: 26px;
          align-items: center;
          flex-direction: column;
          display: flex;
          height: 50px;
          padding-left: 35px;
          padding-right: 35px;
          &__price {
            font-style: normal;
            font-weight: 600;
            font-size: 30px;
            line-height: 38px;
            color: #000000;
          }
          &__unit {
            margin-left: 8px;
            margin-bottom: 0;
            margin-top: 0;
            opacity: 0.5;
            font-size: 13px;
            text-align: center;
          }
          margin-bottom: 32px;
        }

        &__featureList {
          width: 100%;
          border-top: 1px solid #d9d9d9;
          height: 290px;
          display: flex;
          flex-direction: column;
          margin-top: 19px;
          font-weight: 400;
          font-size: 14px;
          line-height: 22px;
          color: #000000;
          padding: 21px 21px 0px 21px;
          &__includes {
            margin-bottom: 10px;
          }
          div {
            margin-bottom: 16px;
          }
          img {
            margin-right: 8px;
          }
        }
      }
    }
  }
}


@media (max-width:768px){
  .BillingPage {
    height: calc(100% - 100px);
    width: 100%;
    &__header {
      display: flex;
      flex-direction: column;
      border-bottom: 1px solid #f0f0f0;
  
      h3 {
        margin-bottom: 4px;
        font-weight: 600;
        font-size: 20px;
        line-height: 28px;
        font-family: "Inter";
        font-style: normal;
      }
      p {
        margin-top: 0;
        margin-bottom: 30px;
  
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: 0.0025em;
  
        color: #424242;
      }
    }
  
    &__body {
      // height: 100%;
      overflow-y: auto;
      margin-top: 20px;
      min-height: 372px;
      background: #ffffff;
      border: 1px solid #d9d9d9;
      border-radius: 8px;
      padding-top: 50px;
    }
  }
  
  .PricingTable {
    :global {
      .ant-btn {
        background-color: #1890ff;
        border-radius: 2px;
        height: 40px;
        font-size: 16px;
        padding: 5px 16px;
        width: 160px;
      }
    }
    background-color: rgb(255, 255, 255);
    font-family: Lato, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica Neue,
      Ubuntu, sans-serif;
    height: 100%;
    opacity: 1;
    padding: 4px;
    transition: opacity 0.2s linear;
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    box-sizing: border-box;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  
    &__tabsContainer {
      margin-left: 20px;
      margin-bottom: 65px;
      color: hsla(0, 0%, 10%, 0.9);
      font-size: 16px;
      display: flex;
      &__tabsList {
        display: flex;
        background-color: hsla(0, 0%, 10%, 0.05);
        border-color: hsla(0, 0%, 10%, 0.1);
        border: 1px solid hsla(0, 0%, 10%, 0.1);
        border-radius: 8px;
        padding: 2px;
  
        &__item {
          z-index: 2;
          background-color: transparent;
          border-radius: 6px;
          font-size: 14px;
          font-weight: 400;
          height: auto;
          line-height: 20px;
          padding: 8px 32px;
          cursor: pointer;
        }
      }
    }
  
    &__container {
      margin-bottom: 36px;
      display: grid;
      grid-gap: 16px;
      grid-template-columns: auto;
      justify-content: center;
      width: 100%;
      &__priceColumn {
        font-family: Lato, -apple-system, BlinkMacSystemFont, Segoe UI,
          Helvetica Neue, Ubuntu, sans-serif;
        border: 1px solid black;
        display: flex;
        width: 270px;
        padding: 16px 0px;
        color: #1a1a1a;
        border: 1px solid #d9d9d9;
        border-radius: 8px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        &__flag {
          position: absolute;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          padding: 10px 20px;
          width: 270px;
          top: -42px;
          background: #e6f7ff;
          border: 1px solid #bae7ff;
          border-radius: 8px 8px 0px 0px;
          font-family: "Inter";
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          color: #096dd9;
        }
        &__header {
          font-style: normal;
          font-weight: 600;
          font-size: 18px;
          line-height: 24px;
          color: #262626;
          margin-bottom: 10px;
        }
  
        &__content {
          width: 100%;
          margin-top: 4px;
          display: flex;
          flex-direction: column;
          align-items: center;
          &__input {
            margin-bottom: 16px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            font-family: "Inter";
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
            color: #262626;
            height: 38px;
            button {
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-items: center;
              padding: 4px;
              gap: 10px;
              width: 24px;
              height: 26px;
              background: #ffffff;
              border: 1px solid #d9d9d9;
              border-radius: 2px;
              cursor: pointer;
            }
            &__wrapper {
              position: relative;
              margin-left: 8px;
              margin-right: 8px;
              width: 59px;
              background: #ffffff;
              border: 1px solid #d9d9d9;
              border-radius: 2px;
              height: 26px;
              input {
                height: 24px;
                border: none;
                width: 57px;
                margin: 0;
                padding-left: 32px;
              }
              img {
                position: absolute;
                bottom: 5px;
                left: 13px;
              }
            }
          }
          &__priceContainer {
            margin-bottom: 26px;
            align-items: center;
            flex-direction: column;
            display: flex;
            height: 50px;
            padding-left: 35px;
            padding-right: 35px;
            &__price {
              font-style: normal;
              font-weight: 600;
              font-size: 30px;
              line-height: 38px;
              color: #000000;
            }
            &__unit {
              margin-left: 8px;
              margin-bottom: 0;
              margin-top: 0;
              opacity: 0.5;
              font-size: 13px;
              text-align: center;
            }
            margin-bottom: 32px;
          }
  
          &__featureList {
            width: 100%;
            border-top: 1px solid #d9d9d9;
            height: 290px;
            display: flex;
            flex-direction: column;
            margin-top: 19px;
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
            color: #000000;
            padding: 21px 21px 0px 21px;
            &__includes {
              margin-bottom: 10px;
            }
            div {
              margin-bottom: 16px;
            }
            img {
              margin-right: 8px;
            }
          }
        }
      }
    }
  }
  
}