.DateAttributeInlineEditingField {
    margin-bottom: 10px;
    // border: 0.1px solid rgba(211, 211, 211, 0.5215686275);
    padding: 10px;
  

    &__InputField {
        outline: 0px;
        border: 0px;
        border-bottom: 1px solid #8C8C8C;
        box-shadow: none;
        &::placeholder {
            color: #bfbfbf !important; // Override placeholder text color
          }
        // :hover{
        // border-bottom: 1px solid #8C8C8C;
        // }
        :global .ant-select-selection-placeholder {
            color: #bfbfbf !important;
          }
          
        :global .ant-input::placeholder {
            color: #bfbfbf !important;
        }
    }

    &__InputField:focus,
    &__InputField:active {
        border-bottom: 1px solid #8C8C8C;
        box-shadow: none;
    }

    &__IconContainer {
        display: flex;
        gap: 10px;
        color: #8C8C8C;

    }

    &__AttributeContainer {
        display: flex;
        justify-content: space-between;

    }

    &__FieldContainer {
        &__FieldTrigger {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
            color: #262626;
        }
    }

    &__Label {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        color: #8C8C8C;
    }

    &__DeleteIcon:hover {
        color: #CF1322;
        cursor: pointer;
    }

    &__InfoIcon:hover {
        color: #1890ff;
        cursor: pointer;
    }
}


.DateAttributeInlineEditingFieldTable {
    margin-bottom: 10px;
    // border: 0.1px solid rgba(211, 211, 211, 0.5215686275);
    padding: 10px;
  

    &__InputField {
        outline: 0px;
        border: 0px;
        border-bottom: 1px solid #8C8C8C;
        box-shadow: none;
    }

    &__InputField:focus,
    &__InputField:active {
        border-bottom: 1px solid #8C8C8C;
        box-shadow: none;
    }

    &__IconContainer {
        display: flex;
        gap: 10px;
        color: #8C8C8C;

    }

    &__AttributeContainer {
        display: flex;
        justify-content: space-between;

    }

    &__FieldContainer {
        &__FieldTrigger {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
            color: #262626;
        }
    }

    &__Label {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        color: #8C8C8C;
    }

    &__DeleteIcon:hover {
        color: #CF1322;
        cursor: pointer;
    }

    &__InfoIcon:hover {
        color: #1890ff;
        cursor: pointer;
    }
}
