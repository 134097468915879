.CreateMessageTemplate {

    &__container {
        display: flex;
        // border: 1px solid rgb(210, 181, 181);
        height: 100%;
        justify-content: center;
        align-items: flex-start;

        &__form {
            width: 50%;
        }

        &__preview {
            width: 50%;

            &__content {
                background: #E6F7FF;
                padding: 5px 10px;
                max-height: 300px;
                overflow: auto;
                min-height: 30px;
                width: 100%;
                pre{
                    white-space: pre-wrap;
                    word-break: keep-all
                }
            }
        }
    }

}