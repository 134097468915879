.carousel-center-container {
  position: relative;
  width: 100%;
  height: 100%;
  margin: auto;
  text-align: center;
  border-radius: 0 8px 8px 0;
}

.carousel-content {
  text-align: center;
  position: absolute;
  left: 15%;
  right: 15%;
  top: 35px;
  z-index: 10;
}

Slider img {
  position: absolute;
  height: 100%;
}

.slick-slider {
  height: 100%;
}

.slick-track,
.slick-list {
  height: 100%;
}

/* .slick-slider img {
  height: 100%;
}

.slick-slide div {
  height: 100%;
} */

/******************************************************slick-slider css******************************************************************/

.slick-dots.slick-thumb {
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: absolute;
  bottom: 80px;
}

.slick-dots.slick-thumb li {
  width: 6px;
  height: 6px;
  margin: 0 3px;
  border-radius: 999px;
  transition: all 0.2s ease-in-out;
  will-change: width;
  background-color: white;
}

.slick-dots.slick-thumb li.slick-active {
  background-color: white;
  width: 96px;
}

/*********************************************overriding the default css of slick slider***************************************************************/
.slick-slider {
  height: 100%;
}

.slick-list {
  height: 100%;
}

.slick-track {
  height: 100%;
}

.slick-slide {
  height: 100%;
}

/* .slick-slider div {
  height: 100%;
} */

.slick-slider div img {
  height: 100%;
}

/************************************************carousel text****************************************************************/
.carousel-content h3.small {
  font-size: 1.5rem;
}

.carousel-content h3.medium {
  font-size: 2rem;
}

.carousel-content h3.large {
  font-size: 2.5rem;
}

.carousel-content p.small {
  font-size: 1rem;
}

.carousel-content p.medium {
  font-size: 1.3rem;
}

.carousel-content p.large {
  font-size: 1.6rem;
}

/******************************************************Carousel button stylings***************************************************************/
.carousel-btn {
  position: absolute;
  left: 25%;
  right: 25%;
  bottom: 100px;
  width: 50%;
  text-align: center;
}

.carousel-btn button {
  padding: 4px 20px 0;
  font-weight: 500;
  background: white;
  font-size: 14px;
  color: rgba(9, 109, 217, 1);
  border-radius: 100px;
  width: 100%;
  height: 36px;
}

.carousel-btn button span+span {
  font-weight: 500;
  font-size: 16px;
  margin-left: 15px;
  color: rgba(9, 109, 217, 1);
  height: 36px;
}

/**************************************************media query for carousel*********************************************************************/

@media (min-width: 980px) {
  .carousel-btn {
    left: 25%;
    right: 25%;
    width: 45%;
    margin: auto;
    display: none;
  }
}

@media (max-width: 980px) {
  .carousel-center-container {
    border-radius: 0px;
  }

  .slick-dots.slick-thumb {
    bottom: 160px;
  }
}