.ContactDrawer {
    width: 100%;
    max-width: 300px;
    font-family: 'Inter';
    $color-gray: rgba(96, 95, 102, 1);
    padding: 24px;
    // overflow: auto;


    &__TriggerButton {
        position: relative;
        left: -50px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 12px;
        gap: 10px;
        width: 44px;
        height: 44px;
        background: #FFFFFF;
        border-radius: 4px;
        color: #096DD9;
        cursor: pointer;
    }

    &__Body {
        padding-top: 11px;
        margin-top: -40px;
        overflow: auto;
        height: 90%;
/* Apply scrollbar styles */
scrollbar-width: thin;
scrollbar-color: #888888 #f5f5f5;

&::-webkit-scrollbar {
    width: 8px;
}

&::-webkit-scrollbar-thumb {
    background-color: #bfbfbf;
    border-radius: 8px;
}

&::-webkit-scrollbar-track {
    background-color: #f5f5f5;
}
    }


    &__Header {
        display: flex;
        justify-content: space-between;
        // align-items: center;
        padding-bottom: 11px;
        border-bottom: 1px solid #F0F0F0;

        &__Body {
            flex: 1;

            h6 {
                font-family: 'Inter';
                font-style: normal;
                font-weight: 400;
                font-size: 20px;
                line-height: 28px;
                display: flex;
                align-items: center;
                color: #262626;
                margin-bottom: 0px;
            }

            p {
                font-family: 'Inter';
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 20px;
                display: flex;
                align-items: center;
                color: #595959;
                margin-bottom: 0px;
            }
        }

        &__Prefix {
            padding-right: 8px;
        }

        &__Heading {
            margin: 0;
            font-weight: 500;
        }
    }

    &__DataField {
        margin-bottom: 10px;

        &__TagsContainer {
            display: flex;
            gap: 4px;
        }

        &__Tags {
            padding: 1px 8px;
            gap: 4px;
            height: 22px;
            background: #FFF7E6;
            border: 1px solid #FFD591;
            border-radius: 2px;
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 20px;
            color: #FA8C16;
        }

        &__Label {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
            color: #8C8C8C;
        }

        &__Value {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
            color: #262626;
        }
    }

    &__AttributeList {
        margin-top: 10px;
        display: flex;
        width: 100%;
        gap: 8px;
        flex-wrap: wrap;
        height: 120px;
        align-content: flex-start;
        overflow: hidden;

        &__Expand {
            height: auto;
        }


        &__Tag {
            display: flex;
            align-items: center;
            padding: 5px 12px;
            gap: 8px;
            height: 32px;
            background: #F5F5F5;
            border-radius: 4px;
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
            color: #262626;
            cursor: pointer;
        }

        &__Footer {
            display: flex;
            justify-content: flex-end;
            width: 100%;

            span {
                font-family: 'Inter';
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 22px;
                color: #1890FF;
                cursor: pointer;
            }
        }
    }

    &__icons {
        color: #1890FF;
    }

    &__Placeholder {
        width: 100%;
        text-align: center;
        color: #8C8C8C;
        font-family: "Inter";
        font-style: normal;
        margin-top: 10px;
    }
}

.AddAttributeButton {
    overflow: auto;
    position: relative;
    // top: -20px;
    padding: 2px 8px 3px 6px;
    gap: 4px;
    width: 122px;
    height: 26px;
    border-radius: 2px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #262626;

}

.AddAttributeButtonContainer {
    display: flex;
    width: 100%;
    flex-direction: row-reverse;
}

.UsernameContainer {
    display: flex;
    gap: 10px;
    align-content: center;
    padding-top: 12px;
    padding-bottom: 23px;

    &__Input {
        flex: 1;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        width: 100%;
        line-height: 24px;
        color: #1E1E1E;
        outline: 0px;
        border: 0px;
        background-color: transparent;
        padding-bottom: 2px;
        /* Add some bottom padding to create a visual cue */
        transition: background-color 0.3s ease;
        /* Add a smooth transition effect */
        // text-decoration: underline;
        /* Add underline to the text */
        text-decoration-color: rgba(0, 0, 0, 0.2);
        /* Set the underline color */
        cursor: text;
        color: #333;

        white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 85%;  /* adjust this percentage according to your design needs */
    display: block;

        &:hover {
            // Styling for the hover effect
            background-color: #ffffff;
            border-radius: 3px;
        }

        &:focus {
            // Styling for when the input field is focused
            outline: none;
            // box-shadow: 0 0 3px #999;
        }
    }

}

.DeleteModal {
    display: flex;
    flex-direction: column;

    &__Modal {
        max-width: 400px;
    }

    &__Body {
        display: flex;
        gap: 17.5px;

        &__Icon {
            color: #FF4D4F;
        }

        &__IconContainer {}

        &__ContentContainer {
            display: flex;
            flex-direction: column;
            text-align: left;

            h5 {
                font-family: 'Inter';
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 24px;
                color: #262626;
            text-align: left;
                
            }

            p {
                font-family: 'Inter';
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 22px;
                color: #262626;
            text-align: left !important;

            }
        }
    }

    &__Footer {
        display: flex;
        flex-direction: row-reverse;
        gap: 8px;
    }
}

.PopoverContent {
    width: 270px;
    height: 242px;
    background: #FFFFFF;
    border-radius: 2px;
    padding: 24px 12px;
    overflow-x: hidden;
    overflow-y: auto;
}

@media (max-width: 768px) {
    .ContactDrawer{
        &__Body{
            // margin-top: 10px;

        }
    }

}