.dropdown-container {
    position: absolute;
    top: 60px; /* Adjust based on your header height */
    right: 0;
    width: 250px;
    background-color: #FFFFFF;
    border: 1px solid #D9D9D9;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    display: none;
  
    @media (max-width: 768px) {
      width: 100%;
      right: 0;
    }
  }
  .listItem {
    // background-color: red;
    font-size: 14px;
    font-weight: 500;
    text-decoration: none;

    &:hover {
      color:'#fff'
    }

  }
  
  .dropdown-header {
    display: flex;
    align-items: center;
    padding: 16px;
    border-bottom: 1px solid #D9D9D9;
  }
  
  .dropdown-header img {
    border-radius: 50%;
    margin-right: 10px;
  }
  
  .dropdown-header div {
    font-size: 16px;
    font-weight: 700;
    color: #262626;
  }
  
  .dropdown-menu {
    list-style: none;
    margin: 0;
    padding: 0px 0px 0px 8px;
  }
  
  .dropdown-menu li {
    padding: 12px 16px;
    display: flex;
    align-items: center;
    cursor: pointer;
    text-decoration: none;
  
    &:hover {
      background-color: #f1f1f1;
      color:'#fff'
    }
  }
  
  .dropdown-menu li svg {
    margin-right: 10px;
  }
  
  .show {
    display: block;
  }
  .userName{
    color: #262626;
    font-size: 16px;
    font-weight: 600;
  }
  