.UserAutomationCard {

    &__SubHeader {
        &__Success {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
            color: #237804;
        }

        &__Error {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
            color: #CF1322;
        }

        &__InActive {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
            color: #FA8C16;
        }

        &__Draft {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
            color: #595959;
        }
    }

    &__Card {
        display: flex;
        flex-direction: column;
        padding: 25px 20px;
        gap: 16px;
        width: 325px;
        height: 170px;
        background: #FFFFFF;
        border: 1px solid #D9D9D9;
        border-radius: 4px;
    }

    &__CardHeader {
        display: flex;
        align-items: center;
        width: 100%;
        gap: 4px;
        justify-content: space-between;

        &__Right {
            display: flex;
            gap: 17px;
            align-items: center;
        }
    }

    &__CardIcon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 36px;
        height: 36px;
        background: #1890FF;
        border-radius: 4px;
        padding: 8px;
    }

    &__CardTitle {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;
        color: #595959;
    }

    &__CardContent {}

    &__CardSubTitle {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: #262626;
    }
}