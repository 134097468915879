.MessageTemplates {

    &__empty_state {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 400px;
        justify-content: center;
    }

    &__delete {
        border-radius: 2px;
        background-color: #fff1f0;
        padding: 4px 8px;

        svg {
            height: 22px;
            width: 15px;
            fill: #f5222d;
        }

    }
    &__eye {
        border-radius: 2px;
        background-color: #FAFAFA;
        padding: 4px 8px;
    }

    &__container {
        padding: 20px 28px;
        height: 100vh;
    }

    &__header {
        height: 70px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__left {
        font-family: 'Inter';
        font-style: normal;

        h2 {
            font-weight: 600;
            font-size: 20px;
            line-height: 28px;
        }

        p {
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
            letter-spacing: 0.0025em;
            color: #424242;
        }
    }

    &__right {}

    &__table_container {
        border: 1px solid #D9D9D9;
        border-radius: 8px;
        height: 85%;
        width: 70%;
        padding: 22px 42px;

        h3 {
            margin-bottom: 20px;
            svg{
                margin-bottom: -4px;
            }
        }
    }

    &__activity_buttons {
        display: flex;
        justify-content: space-between;
        margin-bottom: 35px;

        &__searchbar {
            width: 281px;
        }

        &__button {
            width: 182px;
        }
    }
}