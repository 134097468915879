
.dashboard {
    display: flex;
    flex-direction: column;

}
.widgets {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    // padding: 20px;
    flex: 1;
    background-color: "red" ;
    // padding: 10px;
}
.statistic{
    flex: 1;
    padding: 10px;
    margin: 10px;
}

