.Settings_sidebar {
  height: 100%;
  width: 100%;
  &__head {
    padding: 21px 0px;
    border-bottom: 1px solid #f0f0f0;
    h3 {
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 28px;
    }
  }
  &__body {
    padding: 12px 0px;
    height: max-content;
    display: flex;
    flex-direction: column;

    &__tab {
      &.active,
      &:hover {
        background: var(--secondary-color);
        transition: all 0.5s;
      }
      margin-top: 4px;
      display: flex;
      width: 100%;
      padding: 12px;
      border-radius: 4px;
      text-decoration: none !important;
      a {
        color: black !important;
      }
      p {
        color: black !important;
        margin-left: 11px;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 0;
      }
    }
  }
}

@media (max-width: 768px) {
  .Settings_sidebar {
    display: none;
  }
}
