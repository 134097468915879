.DateSelect {
&__select{
    width: 100%;
    border: none;
    padding: 2px 0px;
    ::placeholder{
        color: #bfbfbf !important; // Override placeholder text color
    }
    :global .ant-picker-suffix{
        display: none
    }

}
}

.DateSelectTable {
    &__select{
        width: 100%;
        border: none;
        :global .ant-picker-suffix{
            display: none
        }
        background: none;
    }
    }