.CreateContactDrawer {
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 100%;
    padding: 24px;
    height: 96%;
overflow: scroll;
&__drawer{
    :global .ant-drawer-body {
            overflow: hidden !important;
            flex: 1;
            min-width: 0;
            min-height: 0;
            padding: 0px 0px;
            overflow: auto;
      }
}

    &__Header {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;
        color: #595959;

    }
}

.ButtonContainer {
    display: flex;
    flex-direction: row-reverse;
    gap: 20px;
    width: 100%;
    padding-right: 24px;
}

.UsernameContainer {
    display: flex;
    gap: 10px;
    align-content: center;
    padding-top: 12px;
    padding-bottom: 23px;

    &__Input {
        flex: 1;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        width: 100px;
        line-height: 24px;
        color: #262626;
        outline: 0px;
        border: 0px;
  background-color: transparent;
  padding-bottom: 2px; /* Add some bottom padding to create a visual cue */
  transition: background-color 0.3s ease; /* Add a smooth transition effect */
//   text-decoration: underline; /* Add underline to the text */
  text-decoration-color: rgba(0, 0, 0, 0.2); /* Set the underline color */
  cursor: text;
  color: #333;
  &:hover {
    // Styling for the hover effect
    background-color: #f5f5f5;
    border-radius: 3px;
  }
  
  &:focus {
    // Styling for when the input field is focused
    outline: none;
    // box-shadow: 0 0 3px #999;
  }
    }

}

.Separator {
    padding-top: 21px;
    margin: 0px;
}

.AddAttributeButton {
    overflow: auto;
    position: relative;
    top: -20px;
    padding: 2px 8px 3px 6px;
    gap: 4px;
    width: 122px;
    height: 26px;
    border-radius: 2px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #262626;

}

.AddAttributeButtonContainer {
    display: flex;
    width: 100%;
    flex-direction: row-reverse;
}

.PopoverContent {
    width: 270px;
    height: 242px;
    background: #FFFFFF;
    border-radius: 2px;
    padding: 24px 12px;
    overflow-x: hidden;
    overflow-y: auto;
}

.ContactDrawer {
    &__AttributeList {
        margin-top: 10px;
        display: flex;
        width: 100%;
        gap: 8px;
        flex-wrap: wrap;
        height: 120px;
        align-content: flex-start;
        overflow: hidden;

        &__Expand {
            height: auto;
        }


        &__Tag {
            display: flex;
            align-items: center;
            padding: 5px 12px;
            gap: 8px;
            height: 32px;
            background: #F5F5F5;
            border-radius: 4px;
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
            color: #262626;
            cursor: pointer;
        }

        &__Footer {
            display: flex;
            justify-content: flex-end;
            width: 100%;

            span {
                font-family: 'Inter';
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 22px;
                color: #1890FF;
                cursor: pointer;
            }
        }
    }
}