.InviteContainer {
    &__tabsContainer {
        margin-top: -30px;

        :global {
            .ant-tabs-nav::before {
                border-bottom: 2px solid #d9d9d9;
            }

            .ant-tabs-tab {
                font-size: 16px;
            }
        }
    }

    &__tab {
        font-size: 16px;
        display: flex;
        flex-direction: column;

        &__radioContainer {
            width: 100%;
            align-items: baseline;
        }

        &__tab2Container {
            &__mailGroup {
                display: block;
                width: 100%;

                &__email {
                    width: 80%;
                }

                &__memberSection {
                    width: 25%;
                }
            }
        }

        &__tab2Footer {
            width: 100%;
            display: flex;
            justify-content: flex-end;
            margin-top: 30px;

            &__btnGroup {
                display: flex;
                width: 100%;
                justify-content: flex-end;

                &__btn1 {
                    width: 25%;
                }

                &__btn2 {
                    span+span {
                        margin-left: 3px;
                        font-size: 12px;
                    }
                }
            }
        }

        &__tab1Footer {
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;

            &__buttons {
                display: flex;
                flex-direction: row;
                align-items: baseline;
                padding: 0px 32px 20px 30px;
            }

            &__footerText1 {
                font-family: Inter;
                font-style: normal;
                font-weight: normal;
                font-size: 13px;
            }
        }
    }

    .InviteContainer__tab__tab1Footer__footerBtn2 {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-left: 16px;
    }

    &__divider {
        border-top: 2px solid rgba(217, 217, 217, 1);
    }
}

@media (max-width: 768px) {
    .InviteContainer {
        &__tabsContainer {
            margin-top: 0;
        }

        .InviteContainer__tab__tab1Footer {
            flex-direction: row;
            flex-wrap: wrap;
        }

        &__tab {
            &__tab1Footer {
                justify-content: center;
                flex-direction: column;

                &__footerBtn1 {
                    width: 100%;
                    margin-right: 3px;
                    padding: 0 4px;
                    margin-bottom: 5px;
                    border: 1px solid rgba(217, 217, 217, 1);

                    &__footerText1 {
                        font-family: Inter;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 13px;
                        line-height: 33px;
                        color: #595959;
                        margin-right: 13px;
                        padding: 1px;
                    }
                }

                &__memberSection {
                    width: auto;
                }

                &__footerBtn2 {
                    width: auto;
                    margin-left: 4px;
                    padding: 0 2px;

                    span+span {
                        margin-left: 3px;
                        font-size: 11px;
                    }
                }
            }
        }
    }
}
