.EditWhatsappDesktopChannel {
    width: 100%;

    &__Header {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: #000000;
    }

    &__SubHeader {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        color: #595959;
        margin-top: 8px;
    }
}


.ChannelForm {
    padding-bottom: 16px;
    padding-top: 20px;

    &__PhoneNumberHelper {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;
        color: #595959;
    }

    &__ButtonContainer {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    &__formContainer {
        width: 100%;
        margin: 0 auto;

        :global {
            .ant-row {
                display: block;
                margin: 20px auto;
                padding: 10px 0;
            }

            label {
                font-style: normal;
                font-size: 16px;
                line-height: 24px;
                color: #616161;

                &::after {
                    content: '' !important;
                }
            }

            Input {
                width: 100%;
                height: 40px;
                padding: 5px, 12px, 5px, 12px;
            }

            .ant-input-affix-wrapper {
                padding: 0 10px;
            }
        }

        &__error {
            color: red;
            height: 0;
        }
    }
}

.ResultInfo {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 9px 16px;
    gap: 10px;

    width: 100%;
    height: 62px;

    /* Daybreak Blue / blue-1 */
    background: #E6F7FF;
    border-radius: 4px;

    p {
        margin-bottom: 0px;
    }
}

.ResultDetails {
    margin-bottom: 40px;

    &__Placeholder {
        margin-bottom: 8px;
        margin-top: 16px;
    }
}

.WhatsappModal {

    &__Icon {
        color: #1890FF;
    }

    &__ImageContainer {
        display: flex;
        width: 100%;
        margin-bottom: 16px;
    }

    &__ImageIcon {
        width: 60px;
        margin-left: auto;
        margin-right: auto;
    }

    &__Heading {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 32px;

        /* identical to box height, or 133% */
        text-align: center;

        color: #000000;
    }

    &__Paragraph {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;

        /* or 150% */
        text-align: center;

        /* Gray / gray-8 */
        color: #595959;
    }

    &__ButtonContainer {
        display: flex;
        flex-direction: row-reverse;
        margin-top: 20px;
    }
}