.AutomationTagsContainer {
    width: 100%;
    min-height: 22px;
    border: 0px;
    white-space: pre-wrap;
    word-wrap: break-word;

    &__Container {
        width: 100%;
        min-height: 12px;
        background: #FFFFFF;
        border: 1px solid #D9D9D9;
        border-radius: 2px;
        padding: 9px 11px;
    }

    &__Tags {
        margin-top: 10px
    }
}

.AutomationTagsContainer:focus {
    border: 0px solid #D9D9D9;
    outline: 0px;
}