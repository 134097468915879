.ChannelForm {
  width: 100%;
  padding: 10px;

  &__header{
    display: flex;
    justify-content: space-between;
  }

  &__editContainer{
    &__warningtext{
      text-align: left;
    }
    &__delete{
      text-align: left;
    }
  }

  h3 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
  }

  &__HelperText {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #595959;
  }

  &__formContainer {
    width: 100%;
    margin: 0 auto;

    :global {
      .ant-row {
        display: block;
        padding: 16px 0;
      }

      label {
        font-size: 14px !important;

        &::after {
          content: "" !important;
        }
      }

      Input {
        width: 100%;
        height: 40px;
        padding: 5px, 12px, 5px, 12px;
      }

      .ant-input-affix-wrapper {
        padding: 0 10px;
      }
    }

    &__error {
      color: red;
      height: 0;
    }

    &__btnContainer {
      width: 100%;
      margin: 0 auto;
      text-align: center;
      font-family: 'Inter';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      color: #BFBFBF;

      &__cancelBtn {
        float: left;
        height: 32px;
        width: 84px;
        border-radius: 3px;


        margin: 0 0px !important;
      }

      &__createChannelBtn {
        float: right;
        height: 32px;
        width: 256px;
        border-radius: 3px;
        margin: 0 0px;

        span {
          margin-right: 5px;

        }

        &__icon {
          font-size: 16px;
        }
      }

      &__editChannelBtn {
        float: right;
        height: 32px;
        width: auto;
        border-radius: 3px;
        margin: 0 0px;

        span {
          margin-right: 5px;

        }

        &__icon {
          font-size: 16px;
        }
      }
    }
  }
}

@media (max-width: 1290px) {
  .ChannelForm {
    &__formContainer {
      width: 100%;

      &__btnContainer {
        &__createChannelBtn {
          width: 256px;
        }
      }
    }
  }
}

@media (max-width: 667px) {
  .ChannelForm {
    padding: 30px 0;

    h3 {
      padding: 0 7px;
      font-size: 16px;
    }

    &__formContainer {
      width: 100%;
      padding: 15px;

      :global {
        .ant-row {
          margin: 16px 0;
          padding: 0px 0;
        }
      }

      &__btnContainer {
        &__createChannelBtn {
          width: auto;
          margin-top: 20px;
          margin: 0 5px;

          &__icon {
            display: none;
          }
        }
      }
    }
  }
}