.ImportContact {
    &__Header {
        display: flex;
        width: 100%;
        height: 50px;
        justify-content: space-between;
        align-items: center;

        &__Text {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            line-height: 28px;
            color: #000000;
        }
    }

    &__Body {
        width: 100%;
        text-align: left;

        &__Text {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
            color: #262626;
            margin-bottom: 8px;
        }

        &__List {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
            color: #595959;
        }

        &__Status {
            display: flex;
            width: 100%;
            gap: 20px;
            margin-bottom: 20px;

            &__Text {
                font-family: 'Inter';
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 22px;
                color: #262626;

                &__span1 {
                    color: #096DD9;
                }

                &__span2 {
                    color: #389E0D;
                }

                &__span3 {
                    color: #F5222D;
                }
            }
        }
    }

    &__Footer {
        display: flex;
        width: 100%;
        justify-content: space-between;

        &__Button {
            height: 40px;
        }
    }

    &__AttributeSkippingMessage {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 9px 16px;
        gap: 10px;
        width: 366px;
        height: 40px;
        background: #E6F7FF;
        border-radius: 4px;
        margin-top: 23px;

        &__Icon {
            color: #1890FF;
        }
    }
}

.Completed {

    &__SkippedContact {
        color: #FF4D4F;
        font-size: 14px;
        font-family: Inter;
        line-height: 22px;
        text-decoration-line: underline;
        margin-top: 8px;
        margin-bottom: 16px;
        text-decoration: underline;
        cursor: pointer;
    }

    &__Image {
        width: 60px;
    }

    &__SuccessMessage {
        margin-top: 16px;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 32px;
        text-align: center;
        color: #000000;
    }

    &__Subtitle {
        padding-top: 8px;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        color: #595959;
    }

    &__AttributeSkippingMessage {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 9px 16px;
        gap: 10px;
        width: 255px;
        height: 40px;
        background: #E6F7FF;
        border-radius: 4px;
        margin-top: 23px;
        margin-left: auto;
        margin-right: auto;
    }
}