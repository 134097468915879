.offlineBadge {
  background-color: #333333;
  color: white;
  text-align: center;
  position: fixed;
  top: 0;
  left: 45%;
  width: 320px;
  z-index: 1000;
  border-radius: 10px;
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;

}

.offlineBadgeMessage{
  margin-bottom:0px !important;
  align-items:center;
  justify-content:center;
}
