.SegmentationItem{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 5px 10px;
        margin-bottom: 5px;
        span{
            padding-right: 25px;
        }

        p {
            margin-bottom: 0px;
        }
       
        &__dropdown{
            display: none;
        }
  &:hover {
    background-color: #E6F7FF !important;
    cursor: pointer;
    
    .SegmentationItem__count {
      display: none;
    }
    
    .SegmentationItem__dropdown {
      display: block;
      :global .anticon.anticon-more{
        padding-right: 0px;
      }
    }
  }
    
}

.DeleteModal {
  display: flex;
  flex-direction: column;

  &__Modal {
      max-width: 400px;
  }

  &__Body {
      display: flex;
      gap: 17.5px;

      &__Icon {
          color: #FF4D4F;
      }

      &__IconContainer {}

      &__ContentContainer {
          display: flex;
          flex-direction: column;
          text-align: left;

          h5 {
              font-family: 'Inter';
              font-style: normal;
              font-weight: 600;
              font-size: 16px;
              line-height: 24px;
              color: #262626;
          }

          p {
              font-family: 'Inter';
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 22px;
              color: #262626;
              text-align: left;
          }
      }
  }

  &__Footer {
      display: flex;
      flex-direction: row-reverse;
      gap: 8px;
  }
}
.SegmentationModal {
  :global .ant-modal-body {
      padding: 40px;
      display: flex;
      flex-direction: column;
  }

  &__body {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      h3 {
          font-size: 16px;
          font-weight: bold;
          margin-bottom: 10px;
      }
      p{
          color: grey;
      }
  }

  &__footer {
      margin-left: auto;
      button:first-child {
          margin-right: 8px;
        }
  }
}