.SegmentationDrawer {
    display: flex;
    flex-direction: column;
    width: 280px;
    flex-shrink: 0;
    padding: 22px;

    &__header {
        display: flex;
        justify-content: space-between;
        width: 100%;
        border-bottom: 0.1px solid lightgrey;
        h4{
            font-size: 18px;
        }
    }

    &__body {
        padding: 15px 0px;

        &__list {
            width: 100%;

            ul {
                width: 100%;
                list-style: none;
                padding-left: 0px !important;

                li {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding: 5px 10px;
                    margin-bottom: 5px;
                    span{
                        padding-right: 25px;
                    }

                    p {
                        margin-bottom: 0px;
                    }
                    &:hover{
                        background-color: #E6F7FF !important;
                        cursor: pointer;
                    }
                }
            }
        }
    }
}