@import "antd/dist/reset.css";

.NodeShell {
  display: flex;
  height: 100%;
}

.TreeContainer {
  display: flex;
  flex-direction: column;
  padding: 12px 20px 20px 0px;
  min-width: 320px;
  max-width: 377px;
  height: 100%;
  flex-shrink: 0;
  flex: 1;
  background-color: #ffffff;
  border-right: 1px solid rgb(229 231 235)
}

.IframeContainer {
  flex: 2;
}

.TreeWrapper {
  display: flex;
  width: 100%;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;
}

.selectedNode {
  background: cornflowerblue;
  color: white;
}
.selectedNodeHeading {
  // background: cornflowerblue;
  display: flex;
  justify-content: center;
  color: white;
  border-bottom: 1px solid rgb(203, 204, 207)
}

.nodeWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.nodeWrapper>div {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.pinIcon {
  cursor: pointer;
  position: absolute;
  right: 0px;
  padding-right: 20px;
}

.TreeContainer {
  :global .ant-collapse-header {
    padding: 0px;
  }

  :global .ant-collapse-header-text {
    font-weight: 400;
    font-size: 14px;
    color: #00000073
  }
  :global .ant-collapse-content {
    font-size: 14px;
    color: #3a3a3ad9;

  }
}

.button {
  cursor: pointer;
}

.activeButton {
  font-weight: 600;
}

.activeTab {
  position: relative;
  padding-bottom: 15px;
  /* Adjust the margin as needed */
}

.activeTab::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 4px;
  /* Adjust the height as needed */
  background-color: #096dd9;
  bottom: 0;
  left: 0;
}

.customButton {
  // flex-basis: 10%;
  width: 96px;
  height: 34px;
  flex-shrink: 0;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  background: white;
  color: #055b7f;
  font-weight: bold;
  font-size: large;
  gap: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  cursor: pointer;
}


.timer {
  display: flex;
  padding: 6px;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 21px;
  position: relative;
  right: -86px;
  flex-basis: 50%;
  border: 1px solid rgb(203, 204, 207);
  border-radius: 9px;

}




.globalTimerContainer {
  display: flex;
  justify-content:center ;
  
  // border-bottom: 1px solid rgb(203, 204, 207); ;
}

.global {
  display: flex;
  height: 45px;
  justify-content: space-between;
  width: 100%;
  gap: 10px;
}

.globalHeader {
  flex-basis: 50%;
  width: 79px;
  height: 37px;
  flex-shrink: 0;
  border-radius: 14.5px;
  background: #ededed;
  padding-top: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}


.LoaderContainer{
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  height: 60vh;
}

.LottieContainer{
  width: 250px; 
  height: 250px; 
}

.node {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px;
  cursor: pointer;

  &:hover {
    background-color: #f0f0f0;
  }
}

.nodeContent {
  display: flex;
  align-items: center;
  width: 100%;
}

.nodeTitle {
  flex-grow: 1;
}

.addIcon {
  margin-left: 10px;
  cursor: pointer;
  color: #1890ff;

  &:hover {
    color: #40a9ff;
  }
}
