.SideNavMobile {
    border-right: 0.5px solid lightgrey;
    width: 100%;
    padding: 20px 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    display: none;
    @media (max-width: 768px) {
        width: 100%;
        right: 0;
      }

    &__ProfileImage {
        // Styles for the profile image container
        width: 44px;
        height: 44px;
        border-radius: 50%;
        background-color: #1890FF;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 10px;
      }
  
      &__ProfileInitial {
        // Styles for the profile initial
        font-size: 24px;
        font-weight: bold;
        color: white;
      }

    &__OrganizationTitle {
        width: 44px;
        height: 44px;
        margin-bottom: 16px;
    }

    &__profile_image_block {
        // align-self: center;
        width: 40px;
        position: relative;
        cursor: pointer;
        display: flex;
        margin-left: 4px;
        font-size: 12px;
    }

    &__profile_image {
        border-radius: 50%;
        width: 36px;
        z-index: 100;
    }

    &__online_status_circle {
        border-radius: 50%;
        border: 3px solid #0a6cd9;
        right: 7px;
        width: 18px;
        height: 18px;
        background: #00e676;
        bottom: 0;
        left: 23px;
        position: absolute;
    }

    &__bottom {
        display: flex;
        flex-direction: column;
        gap: 16px;
    }

    &__active {
        background: #096DD9;
        border-radius: 6px;
        color: white !important;
        position: relative;
    }

    &__icon {
        background: none;
        cursor: pointer;
        border-radius: 6px;
        position: relative;
        >span{
            margin-left: 10px;
        }
    }

    &__count {
        position: absolute;
        top: -10px;
        right: -10px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 20px;
        height: 20px;
        background-color: grey;
        border-radius: 50%;
        color: white;
        font-size: 12px;
        font-weight: bold;
      }

    &__icon:hover {
        background-color: #f5f5f5;
        border-radius: 6px;
    }

    nav a {
        width: 40px;

        div {
            text-decoration: none;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            width: max-content;
            font-size: 12px;
            height: 40px;
            padding: 0px 10px;
        }
    }
}