.Profile {
  width: 100%;
  :global span.ant-page-header-heading-extra{
    margin: 0px !important;
  }
  :global .ant-page-header-heading-left{
    margin: 0px !important;
  }

  &__center {
    margin: 0 auto;
    margin-top: 64px;

    &__pic {
      position: relative;

      &__items {
        width: fit-content;
        margin: auto;

        img {
          height: 180px;
          width: 180px;
          border-radius: 50%;
        }

        div {
          width: fit-content;
        }

        &__uploadImg {
          position: relative;
          bottom: 47px;
          left: 122px;
          background: #1890ff !important ;
          color: white !important;
          border: none !important;
        }
      }
    }

    &__description {
      padding-top: 20px;

      &__icon {
        display: flex;
        align-items: center;
        margin-bottom: 12px;

        &__fixed {
          margin-right: 20px;
        }

        &__edit {
          color: rgba(89, 89, 89, 1);
          margin-left: 20px;
        }

        :global(.anticon) {
          font-size: 18px;
        }
      }

      &__form {
        width: 75%;
        margin-bottom: 0 !important;

        div {
          text-align: left;

          label {
            font-size: 16px;
            font-weight: 400;
            color: rgba(89, 89, 89, 1);
          }
        }

        :global {
          .ant-form-item-control-input-content {
            display: flex;
          }
          .ant-input {
            font-size: 16px;
            color: black;
            font-weight: 400;
          }
        }
      }

      &__content {
        font-size: 16px;
        font-weight: 600;

        &__loading_layout {
          color: grey;
        }
      }
    }

    &__error {
      margin-bottom: 10px;
    }
  }
}

@media (max-width: 980px) {
  :global .ant-page-header.ant-page-header-compact{
    margin-top: 60px;
  }
  .Profile {
    margin-top: -55px;
    padding-bottom: 20px;
    &__center {
      width: 90%;

      :global(.ant-row) {
        display: block;
      }

      &__pic {
        margin: 0 auto;
        width: 100%;
        max-width: 100% !important;
      }

      &__description {
        width: 100%;
        max-width: 100% !important;

        &__icon {
          border-bottom: 1px solid gray;

          &:last-child {
            border-bottom: none;
          }
        }

        &__form {
          width: 90%;

          div {
            margin: 0;
            max-width: 100%;
          }

          :global {
            .ant-form-item-label {
              padding: 0;
            }
            .ant-form-item-control-input {
              min-height: 0;
            }
          }
        }

        &__content {
          width: 100%;

          div {
            float: right;
          }
        }
      }
    }
  }
}
