.CsvTable {
    &__TableContainer {
        max-width: 100%;
        height: 300px;
        overflow: auto;
    }
}

.Table {
    border: 1px solid #F0F0F0;
    border-radius: 2px;

    thead {
        tr {
            display: flex;
        }

        th {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 16px;
            gap: 10px;
            width: 200px;
            height: 54px;
            background: #FAFAFA;
            border-bottom: 1px solid #F0F0F0;
        }
    }

    tbody {
        tr {
            display: flex;
        }
    
        td {
            white-space: normal;
            max-height: 7.8em;
  width: 200px;
  background: #FFF;
  border-bottom: 1px solid #F0F0F0;
  padding: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
        }
    }
    


}