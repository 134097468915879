.CreateAutomationRuleModal {
    &__drawer {
        :global .ant-drawer-content-wrapper {
            width: 727px !important;
        }

    }

    &__TriggerButton:hover {
        background-color: #D9D9D9;
    }

    &__TriggerButton {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 20px;
        gap: 4px;
        width: 325px;
        height: 170px;
        background: #f0f0f0;
        border: 1px dashed #D9D9D9;
        border-radius: 4px;

       

        div {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            text-align: center;
            color: #595959;
        }
    }


    &__Header {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        &__Title {
            height: 28px;
            font-family: 'Inter';
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 28px;
            color: #262626;
        }
        &__text {
            color: var(--gray-gray-8, #595959);

            /* Regular/14px | 22px */
            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px;
            /* 157.143% */
            margin: 16px 0px;
        }
    }

    &__FormContainer {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 32px;
    }

    &__InputContainer {
        display: flex;
        margin-top: 16px;
        width: 100%;
        flex-direction: column;
        gap: 6px;

        &__Label {
            width: 100%;
            font-family: 'Inter';
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 22px;
            color: #595959;
        }
    }

    &__Input {
        padding: 5px 12px;
        gap: 10px;
        width: 452px;
        height: 32px;
        background: #FFFFFF;
        border: 1px solid #D9D9D9;
        border-radius: 2px;
    }

    &__Holder {
        display: flex;
        width: 100%;
        flex-direction: column;
        gap: 16px;
    }

    &__Footer {
        display: flex;
        justify-content: space-between;
        padding-top: 32px;
        padding-bottom: 66px;
    }
}

@media (max-width:768px) {
    .CreateAutomationRuleModal {
        &__drawer {
            :global .ant-drawer-content-wrapper {
                width: auto !important;
            }

        }

        &__TriggerButton:hover {
            background-color: #D9D9D9;
        }

        &__TriggerButton {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 20px;
            gap: 4px;
            width: 325px;
            height: 170px;
            background: #f0f0f0;
            border: 1px dashed #D9D9D9;
            border-radius: 4px;

            div {
                font-family: 'Inter';
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 24px;
                text-align: center;
                color: #595959;
            }
        }


        &__Header {
            width: 100%;
            display: flex;
            justify-content: space-between;

            &__Title {
                height: 28px;
                font-family: 'Inter';
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 28px;
                color: #262626;
            }
        }

        &__FormContainer {
            display: flex;
            flex-direction: column;
            width: 100%;
            gap: 32px;
        }

        &__InputContainer {
            display: flex;
            margin-top: 16px;
            width: 100%;
            flex-direction: column;
            gap: 6px;

            &__Label {
                width: 100%;
                font-family: 'Inter';
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 22px;
                color: #595959;
            }
        }

        &__Input {
            padding: 5px 12px;
            gap: 10px;
            width: auto;
            height: 32px;
            background: #FFFFFF;
            border: 1px solid #D9D9D9;
            border-radius: 2px;
        }

        &__Holder {
            display: flex;
            width: 100%;
            flex-direction: column;
            gap: 16px;
        }

        &__Footer {
            display: flex;
            justify-content: space-between;
            padding-top: 32px;
            padding-bottom: 66px;
        }
    }

}