.Editor {
  font-size: 14px !important;
  color: #262626 !important;

  &::-webkit-scrollbar {
    width: 8px !important;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #888 !important;
    border-radius: 4px !important;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #555 !important;
  }

  scrollbar-width: thin !important;
  scrollbar-color: #888 #eee !important;

  &__rich_text_editor_advanced {
    height: 210px;
    font-family: Inter;
    border: 2px solid #dadada;
    border-radius: 7px;
    -webkit-transition: all 0.30s ease-in-out;
    transition: all 0.30s ease-in-out;
    :global {
      .RichTextEditor__editor___1QqIU {
        font-size:14px;
        height: 152px;
        // Temp commented
        // overflow-y: auto;
      }
    }
    &:focus-within {
      outline: none !important; 
      border-color: #9ecaed;
      // box-shadow: 0 0 6px #9ecaed;
    }
  }
  .Editor__rich_text_editor_simple {
    background-color: #FFFBE6;  // Your desired color for chatChoice "1"
    height: 50%;
}

.Editor__rich_text_editor_advanced {
    background-color:white;  // Your desired color for chatChoice "2"
    height: 50%;

  }


  &__rich_text_editor_simple {
    max-height: 150px;
    overflow: auto;
    font-family: Inter;
    border: 2px solid #dadada;
    border-radius: 7px;
    -webkit-transition: all 0.30s ease-in-out;
    transition: all 0.30s ease-in-out;

    &::-webkit-scrollbar {
      width: 8px !important;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #bfbfbf !important;
      border-radius: 4px !important;
    }

    &::-webkit-scrollbar-thumb:hover {
      background-color: #555 !important;
    }

    scrollbar-width: thin !important;
    scrollbar-color: #888 #eee !important;

    &:focus {
      outline: none !important; // Make sure to override any other styles
      box-shadow: pink;
      border-color: inherit;
  }
  }

  &__input_toolbar_visible {
    display: block;
  }

  &__input_toolbar_hide {
    display: none;
  }
}
