@import "antd/dist/reset.css";

  .selectedNode {
    background: cornflowerblue;
    color: white;
  }


  .nodeWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  
  .iconContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }
  
  .addIcon {
    cursor: pointer;
  }
  
  .nodeWrapper>div {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 6px;
  }

  .pinIcon {
    display: flex;
    cursor: pointer;
    position: absolute;
    right: 0px;
    padding-right: 20px;
    margin-right: 16px;
  }