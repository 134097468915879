.CreateCustomAPIChannel{
    width: 100%;
    &__drawer{
        :global .ant-drawer-content-wrapper{
            width: 534px !important;
          }
        
    }

    &__Header{
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: #000000;
    }

    &__SubHeader {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        color: #595959;
        margin-top: 8px;
    }
}

.ChannelForm {
    padding-bottom: 16px;
    padding-top: 20px;

    &__ButtonContainer {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
}

@media (max-width: 980px) {
    .CreateCustomAPIChannel{
        width: 100%;
        &__drawer{
            :global .ant-drawer-content-wrapper{
                width: auto !important;
              }
            
        }
    
        &__Header{
            font-family: 'Inter';
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            color: #000000;
        }
    
        &__SubHeader {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
            color: #595959;
            margin-top: 8px;
        }
    }
    
    .ChannelForm {
        padding-bottom: 16px;
        padding-top: 20px;
    
        &__ButtonContainer {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
        }
    }
}
