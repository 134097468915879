.DeleteModal {
    display: flex;
    flex-direction: column;

    &__Modal {
        max-width: 400px;
    }

    &__Body {
        display: flex;
        gap: 17.5px;

        &__Icon {
            color: #FF4D4F;
        }

        &__IconContainer {}

        &__ContentContainer {
            display: flex;
            flex-direction: column;
            text-align: left;

            h5 {
                font-family: 'Inter';
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 24px;
                color: #262626;
            text-align: left;
                
            }

            p {
                font-family: 'Inter';
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 22px;
                color: #262626;
            text-align: left !important;

            }
        }
    }

    &__Footer {
        display: flex;
        flex-direction: row-reverse;
        gap: 8px;
    }
}
.SortingPopup {
   
    &__header {
        padding: 10px;
        margin-bottom: 10px;
        border-bottom: 1px solid rgba(211, 211, 211, 0.364);
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        text-align: left;

        &__icons {
            color: grey;
        }

        &__title {
            font-size: 14px;
            font-weight: bold;
        }
        
        &__Input {
            // flex: 1;
            margin-bottom: 10px;
            font-family: 'Inter';
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            width: 100%;
            line-height: 24px;
            color: #262626;
            outline: 0px;
            border: 0px;
            background-color: transparent;
            padding-bottom: 2px;
            /* Add some bottom padding to create a visual cue */
            transition: background-color 0.3s ease;
            /* Add a smooth transition effect */
            text-decoration: underline;
            /* Add underline to the text */
            text-decoration-color: rgba(0, 0, 0, 0.2);
            /* Set the underline color */
            cursor: text;
            color: #333;

            &:hover {
                // Styling for the hover effect
                background-color: #f5f5f5;
                border-radius: 3px;
            }
        }

        &__input {
            width: 100%;

            input[disabled] {
                background-color: rgba(128, 128, 128, 0.414);
                color: grey;
            }
        }
    }

    &__body {
        padding: 10px;

        &__sortrow {
            display: flex;
            align-items: center;
            margin-bottom: 10px;
            cursor: pointer;

            &:hover {
                color: #1890ff;
            }

            &__icon {
                margin-right: 8px;
            }

            &__text {
                font-size: 16px;
            }

        }
    }

    &__footer {
        &__delete {
            width: 100%;
            color: #ff4d4f;
            text-align: left;

            &:hover {
                background-color: #ff4d4f12 !important;
            }
        }
    }
}


.modal {
    :global .ant-modal-body {
        padding: 3px !important;
    }
}

.modalContent {
    padding: 16px;
    display: flex;

    &__left {
        width: 10%;
        margin-right: 16px;
        color: #ff4d4f;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__right {
        width: 90%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        text-align: left;

        &__head {
            font-size: 16px;
            font-weight: 600;
            text-align: left;
        }

        &__body {
            font-size: 14px;
            color: #666;
        }
    }
}



.modalFooter {
    display: flex;
    justify-content: flex-end;
}