.UserSelect {
    position: relative;
    // border: 0.1px solid #d3d3d385;
    padding: 10px;
   
    &:hover {
        .UserSelect__IconContainer {
          display: flex; // Make the info icon container visible on hover
        }
      }
    &__Container {
        display: flex;
        justify-content: space-between;
        margin-bottom: 5px;
    }

    &__Label {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        color: #8C8C8C;
    }


    &__IconContainer {
        display: none;
        gap: 10px;
        color: #8C8C8C;

    }

    &__IconContainer:hover {
        display: flex;
        gap: 10px;
        color: 
    #1890FF
        ;

    }

    &__InfoIcon:hover {
        color: #1890ff;
        cursor: pointer;
    }

    &__container {
        /* Gray / gray-1 */
        position: absolute;
        z-index: 35165;
        top: -44px;
        left: -13px;
        width: 108%;

        background: #FFFFFF;
        /* Elevation */

        box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05), 0px 6px 16px rgba(0, 0, 0, 0.08), 0px 3px 6px -4px rgba(0, 0, 0, 0.12);
        border-radius: 4px;
        padding: 20px;

        &__modal{
            :global .ant-modal-close-x{
                display: none;
            }
        }
        &__wrapmodal{
          position: absolute;
        }

        &__select {
            height: 31px;
            :global {
                .ant-select-selector {
                border: none !important;
                transition: border-color 0.3s;
                &:hover {
                    border-bottom: 1px solid #D9D9D9 !important;
                    /* Make the border visible on hover */
                }

            .ant-select-selection-overflow {
                position: relative;
                display: flex;
                flex: auto;
                flex-wrap: nowrap;
                max-width: 100%;
        
                .ant-select-selection-placeholder {
                    left: 0px !important;
                    /* Customize the placeholder text color */

                }
            }
            .ant-tag{
                border: none !important;
                background-color: #ffffff;
            }
        }
    }

    :global .ant-select-selector .ant-select-selection-placeholder {
        color: #bfbfbf !important;
        /* Change this to your desired color */
        opacity: 0.7;
    }
}
&__select2 {
    height: 31px;

    :global {
        .ant-select-selector {
        border: none !important;
        transition: border-color 0.3s;
        &:hover {
            border-bottom: 1px solid #D9D9D9 !important;
            /* Make the border visible on hover */
        }

    .ant-select-selection-overflow {
        position: relative;
        display: flex;
        flex: auto;
        flex-wrap: nowrap;
        max-width: 100%;

        .ant-select-selection-placeholder {
            left: 0px !important;
            /* Customize the placeholder text color */

        }
    }
    .ant-tag{
        border: none !important;
        margin: 2px;
    }
}
}

:global .ant-select-selector .ant-select-selection-placeholder {
color: #bfbfbf !important;
/* Change this to your desired color */
opacity: 0.7;
}
}


        &__header {
            display: flex;
            justify-content: space-between;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            margin-bottom: 12px;
            /* identical to box height, or 150% */


            /* Gray / gray-9 */

            color: #262626;

        }

        &__body {
            display: flex;
            flex-direction: column;
            
            &__options {
                margin-top: 20px;
                
                &__option {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin-bottom: 6px;
                    cursor: pointer;
                    padding: 4px 2px;

                    &:hover {
                        background-color: whitesmoke;
                    }

                    &__left {
                        display: flex;

                        &__avatar {
                            // height: 100%;
                            margin: auto 0;
                        }

                        &__avatarsm {
                            // height: 12px;
                            // width: 12px;
                            // height: 100%;
                            margin: auto 0;
                            margin-right: 4px;
                            // font-size: 6px;
                        }

                        &__text {
                            display: flex;
                            flex-direction: column;
                            margin-left: 8px;

                            h6 {
                                display: flex;
                                font-style: normal;
                                font-weight: 400;
                                font-size: 14px;
                                line-height: 24px;
                                /* identical to box height, or 171% */
                                margin-bottom: 1px;
                                color: #424242;
                            }

                            span {
                                font-weight: 400;
                                font-size: 12px;
                                line-height: 15px;
                                color: #8C8C8C;

                            }
                        }
                    }
                }
            }
        }

        &__popup {
            display: none;
        }

        &__options {
            border-top: 1px solid #F0F0F0;
            padding-top: 20px;
            margin-top: 20px;

            >p {
                font-weight: 400;
                font-size: 14px;
                line-height: 22px;
                color: #595959;
            }

            &__create {
                display: flex;
                padding: 6px 8px;
                background: #F5F5F5;
                border-radius: 2px;
                align-items: center;

                &__tag {
                    cursor: pointer;
                }

                >span {
                    font-size: 12px;
                    line-height: 20px;
                    color: #000000;
                    margin-right: 4px;
                }
            }
        }
    }
    &__tablecontainer {
        /* Gray / gray-1 */
        // position: absolute;
        // z-index: 35165;
        // top: -44px;
        // left: -13px;
        width: 208%;
        
        // background: #FFFFFF;
        /* Elevation */
        
        // box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05), 0px 6px 16px rgba(0, 0, 0, 0.08), 0px 3px 6px -4px rgba(0, 0, 0, 0.12);
        // border-radius: 4px;
        // padding: 20px;
        
        &__modal{
            :global .ant-modal-close-x{
                display: none;
            }
            :global .ant-modal-content{
                top: 11vh !important;
                background-color: #1890ff;
            }
        }
        &__wrapmodal{
          position: absolute;
        }

        &__select {
            height: 31px;
            :global {
              .ant-select-selector {
                border: none !important; /* Hide the border by default */
                transition: border-color 0.3s; /* Add a transition for a smooth effect */
          
                &:hover {
                  border-bottom: 1px solid #D9D9D9 !important; /* Make the border visible on hover */
                }
          
                .ant-select-selection-overflow {
                  .ant-select-selection-placeholder {
                    left: 0px !important; /* Customize the placeholder text color */
                  }
                }
              }
            }
            :global .ant-select-selector .ant-select-selection-placeholder {
                color: #bfbfbf !important; /* Change this to your desired color */
                opacity: 0.7;
              }
          }

        &__header {
            display: flex;
            justify-content: space-between;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            margin-bottom: 12px;
            /* identical to box height, or 150% */


            /* Gray / gray-9 */

            color: #262626;

        }

        &__body {
            display: flex;
            flex-direction: column;

            &__options {
                margin-top: 20px;

                &__option {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin-bottom: 6px;
                    cursor: pointer;
                    padding: 4px 2px;

                    &:hover {
                        background-color: whitesmoke;
                    }

                    &__left {
                        display: flex;

                        &__avatar {
                            // height: 100%;
                            margin: auto 0;
                        }

                        &__avatarsm {
                            // height: 12px;
                            // width: 12px;
                            // height: 100%;
                            margin: auto 0;
                            margin-right: 4px;
                            // font-size: 6px;
                        }

                        &__text {
                            display: flex;
                            flex-direction: column;
                            margin-left: 8px;

                            h6 {
                                font-style: normal;
                                font-weight: 400;
                                font-size: 14px;
                                line-height: 24px;
                                /* identical to box height, or 171% */
                                margin-bottom: 1px;
                                color: #424242;

                            }

                            span {
                                font-weight: 400;
                                font-size: 12px;
                                line-height: 15px;
                                /* identical to box height */


                                /* Gray/gray-7 */

                                color: #8C8C8C;

                            }
                        }
                    }
                }
            }
        }

        &__popup {
            display: none;
        }

        &__options {
            border-top: 1px solid #F0F0F0;
            padding-top: 20px;
            margin-top: 20px;

            >p {
                font-weight: 400;
                font-size: 14px;
                line-height: 22px;
                color: #595959;
            }

            &__create {
                display: flex;
                padding: 6px 8px;
                background: #F5F5F5;
                border-radius: 2px;
                align-items: center;

                &__tag {
                    cursor: pointer;
                }

                >span {
                    font-size: 12px;
                    line-height: 20px;
                    color: #000000;
                    margin-right: 4px;
                }
            }
        }
    }
}

.NamePlaceholder {
    display: flex;
    justify-content: space-between;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #8C8C8C;
}