.MultiSelectInlineFields {
    transition: height 0.8s ease;
    height: 50px;
    &:hover {
        .MultiSelectInlineFields__IconContainer {
          display: flex; // Make the info icon container visible on hover
        }
      }

    &:hover{
        height: 190px; // Set the expanded height
    }

    &:hover {
		// height: 300px;
		transition: height 500ms ease-in;
		// .MultiSelectInlineFields__selectbox {
		// 	-webkit-transition: max-height 0.8s ease-in-out;
		// 	-moz-transition: max-height 0.8s ease-in-out;
        //     transition: max-height 0.8s ease-in-out;
        //     max-height: 300px; /* Adjust the value as needed */
        //     overflow: visible;
		// }
        .MultiSelectInlineFields__TagsContainer{
            max-height: 0;
            overflow: hidden;
        }
	}

    &__selectbox{
        display: flex;
            flex-direction: column;
            height: 31px;
            // max-height: 0;
            // overflow: hidden;
    }

     &__TagsContainer {
        display: flex;
        gap: 4px;
        flex-wrap: wrap;
        margin-top: 10px;
		            transition: max-height 0.6s ease-in-out;
            max-height: 300px; /* Adjust the value as needed */

    }

    

    &__DataField {
        margin-bottom: 10px;
        // border: 0.1px solid #d3d3d385;
padding: 10px;
    }

    &__Label {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        color: #8C8C8C;
    }

    &__Value {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        color: #262626;
    }

   

    &__Tags {
        padding: 1px 8px;
        gap: 4px;
        height: 22px;
        background: #FFF7E6;
        border: 1px solid #FFD591;
        border-radius: 2px;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;
        color: #FA8C16;
    }

    &__IconContainer {
        display: none;
        gap: 10px;
        color: #8C8C8C;

    }

    &__IconContainer:hover {
        display: flex;
        gap: 10px;
        color: 
    #1890FF
        ;

    }

    &__Container {
        display: flex;
        justify-content: space-between;
        margin-bottom: 5px;
    }

    &__DeleteIcon:hover {
        color: #CF1322;
        cursor: pointer;
    }


    &__InfoIcon:hover {
        color: #1890ff;
        cursor: pointer;
    }
}