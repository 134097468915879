.SelectOptions {
    position: relative;

    &__container {
        // position: absolute;
        width: 100%;
        // top: -32px;
        // z-index: 2123123;
        /* Gray / gray-1 */

        // background: #FFFFFF;
        /* Elevation */

        // box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05), 0px 6px 16px rgba(0, 0, 0, 0.08), 0px 3px 6px -4px rgba(0, 0, 0, 0.12);
        border-radius: 4px;
        // padding: 20px;
        // overflow: auto;
        &__modal{
            :global .ant-modal-close-x{
                display: none;
            }
        }
        &__tags{
          .ant-tag{
            background-color: aqua;
          }
        }
        &__wrapmodal{
          position: absolute;
        }

        &__select {
            :global {
              .ant-select-selector {
                border: none !important; /* Hide the border by default */
                transition: border-color 0.3s; /* Add a transition for a smooth effect */
          
                &:hover {
                  border-bottom: 1px solid #D9D9D9 !important; /* Make the border visible on hover */
                }
          
                .ant-select-selection-overflow {
                  .ant-select-selection-placeholder {
                    left: 0px !important; /* Customize the placeholder text color */
                    color: #bfbfbf;
                  }
                }
              }
             
            }
            /* CustomSelect.css */
:global .ant-select-selector .ant-select-selection-placeholder {
  color: #bfbfbf !important; /* Change this to your desired color */
  opacity: 0.7;
}

          }
          

        &__popup {
            display: none;
        }

        &__options {
            border-top: 1px solid #F0F0F0;
            padding-top: 20px;
            margin-top: 20px;
            height: 200px;
            overflow: auto;

            >p {
                font-weight: 400;
                font-size: 14px;
                line-height: 22px;
                color: #595959;
            }

            &__create {
                display: flex;
                padding: 6px 8px;
                background: #F5F5F5;
                border-radius: 2px;
                align-items: center;

                &__tag {
                    cursor: pointer;
                    color: white;
                }

                >span {
                    font-size: 12px;
                    line-height: 20px;
                    color: white;
                    margin-right: 4px;
                }
            }
        }
    }
}