.AutomationRuleBlock {
    width: 100%;
    border: 1px solid #D9D9D9;
    &__filters {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        padding: 20px 0px;
    
        &__line {
          position: absolute;
          width: 1px;
          height: 100%;
          border-left: 2px dotted #d9d9d9;
          /* Use border instead of background-color */
          left: 50%;
          top: 0;
          transform: translateX(-50%);
          z-index: 1;
        }
      }

    &__Header {
        width: 100%;
        height: 54px;
        padding: 16px 141px 16px 16px;
        gap: 16px;
        background: #FAFAFA;
        border-bottom: 1px solid #D9D9D9;
    }

    &__Footer {
        border-bottom: 0px;
        border-top: 1px solid #D9D9D9;
    }

    &__Placeholder {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        color: #262626;
    }

    &__Radio {
        font-weight: bold;
    }

    &__Footer {}

    &__AddCondition {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        color: var(--primary-color);
        cursor: pointer;
    }


    &__Body {
        display: flex;
        flex-direction: column;
        padding: 16px;
        gap: 16px;
        width: 100%;
        min-height: 64px;

        &__FirstRow {
            display: flex;
            gap: 16px;
        }
    }
}

@media (max-width:768px){
    .AutomationRuleBlock {
        width: 100%;
        border: 1px solid #D9D9D9;
    
        &__Header {
            width: 100%;
            height: 54px;
            padding: 10px;
            gap: 16px;
            background: #FAFAFA;
            border-bottom: 1px solid #D9D9D9;
        }
    
        &__Footer {
            border-bottom: 0px;
            border-top: 1px solid #D9D9D9;
        }
    
        &__Placeholder {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
            color: #262626;
        }
    
        &__Radio {
            font-weight: bold;
        }
    
        &__Footer {}
    
        &__AddCondition {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
            color: #096DD9;
            cursor: pointer;
        }
    
    
        &__Body {
            display: flex;
            flex-direction: column;
            padding: 16px;
            gap: 16px;
            width: 100%;
            min-height: 64px;
    
            &__FirstRow {
                display: flex;
                gap: 16px;
            }
        }
    }
    
}