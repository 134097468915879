.timerContainer {
    display: flex;
    justify-content: flex-start;
    // background-color: #096dd9;
    border: 1px solid rgb(203, 204, 207);
    border-radius: 9px;
    padding-left: 10px;
  
      
  }
  .timerHeader {
    height: 37px;
    flex-shrink: 0;
    border-radius: 14.5px;
    // background: #ededed; 
    padding-top: 5px;
    display: flex;
    align-items: center;
    justify-content: start;
    color: #055b7f;
  
  }

  .Navigation {
    display: flex;
    justify-content: space-between;
    gap: 18px;
    padding: 12px;
    font-weight: 18px;
    border-bottom: 1px solid rgb(203, 204, 207);
    flex:1
    /* Assuming you want to set font weight to bold */
  }

    .timerDetails {
      display: flex;
      justify-content: center;
      align-items: center;
      color: #055b7f;
      padding-right: 10px;
      padding-left: 10px;
      padding-top: 15px;
    }

    .projName {
      background-color: "";
      display: flex;
      justify-content: center;
      align-items: center;
      color: #939393;
      // margin: 10px;
  }
  .dailyTimerHeading{
    display: flex;
    font-weight: bold;
    font-size: medium;
    color: #055b7f;

  }