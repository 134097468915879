.EditCannedResponseForm {

    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 100%;

    &__cursor {
        cursor: pointer;
    }

    &__Header {
        width: 100%;
        display: flex;
        justify-content: space-between;

        &__Title {
            height: 28px;
            font-family: 'Inter';
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            line-height: 28px;
            color: #262626;
        }
    }

    &__Body {
        display: flex;
        flex: 1;
        padding-top: 17px;

        &__Form {
            width: 50%;
            border-right: 1px solid #F0F0F0;
            padding-right: 32px;

        }

        &__Preview {
            display: flex;
            flex-direction: column;
            width: 50%;
            padding-left: 32px;

            &__Title {
                display: flex;
                justify-content: space-between;
                margin-bottom: 8px;
                min-height: 24px;
                align-items: center;

                &__Header {

                    font-family: 'Inter';
                    font-style: normal;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 24px;
                    color: #262626;
                    width: 100px;
                }
            }

            &__Body {
                min-height: 42px;
                background: #E6F7FF;
                border-radius: 4px;
                white-space: pre-line;
                padding: 10px;
            }
        }
    }

    &__ButtonContainer {
        display: flex;
        gap: 12px;
    }

    &__Info {
        display: flex;

        p {
            padding-left: 5px;
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 20px;
            color: #262626;

            span {
                color: #1890FF;
            }
        }
    }

    &__MediaContainer {
        display: flex;
        flex-direction: column;
        padding-top: 24px;
        padding-bottom: 40px;
        gap: 8px;

        &__Label {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 300;
            font-size: 16px;
            line-height: 24px;
            color: #262626;
        }

        &__Info {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 20px;
            color: #8C8C8C;
        }
    }

    &__Error__Title,
    &__Error__Message {
        color: red;
        height: 0;
    }

    &__Error__Message {
        margin-top: 35px;
    }



}