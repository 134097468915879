.CreateCannedUpload {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    width: 100%;

    &__None {
        display: none;
    }

    &__Header {
        width: 100%;
        height: 24px;
        display: flex;
        align-items: center;
        gap: 20px;

        &__Button {
            cursor: pointer;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 1px 8px;
            gap: 8px;
            width: 64px;
            height: 24px;
            border: 1px solid var(--primary-color);
            border-radius: 2px;
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
            color: var(--primary-color);
        }

        button {
            border: 1px solid  var(--primary-color);
            color: var(--primary-color);
        }
    }

    &__Title {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #262626;
        flex: none;
        order: 0;
        flex-grow: 0;
    }

    &__EmptyState {
        img {
            width: 135px;
            height: 108px;
        }

        p {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 20px;
            color: #BFBFBF;
        }
    }

    &__Attachments {
        margin-top: 8px;

        ul {
            padding-left: 0px;
        }
    }
}

.DraggableTag {
    &__CloseIcon {
        margin-left: auto;
        padding: 4px;
    }

    ul {
        padding-left: 0px;
    }

    &__dragtag {
        z-index: 1000000;
        list-style-type: none;
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 4px;
        gap: 16px;
        width: 330px;
        height: 32px;
        background: #F5F5F5;
        border-radius: 4px;
        width: 100%;

        >div {
            display: flex;
            align-items: center;
            width: 100%;

            p {
                overflow: hidden;
                max-width: 380px;
                line-height: 32px;
                margin-bottom: 0px;
                white-space: nowrap;
            }


        }

        >span {
            cursor: pointer;
            margin-left: auto;
            display: flex;
            align-items: center;

            >span {
                margin-right: 10px;
            }
        }

        &__tag {
            cursor: pointer;
        }
    }

    &__draghandle {
        cursor: pointer;
        margin-right: 15px;
        margin-left: 12px;
        height: 100%;
    }

}