.CreateAttributeDrawer {
    width: 100%;
    padding: 24px;
    display: flex;
    flex-direction: column;

    &__Header {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: #262626;
        padding-bottom: 13px;
        padding-left: 0px;
        border: 0px;
        outline: 0px;
        margin-bottom: 14px;
        cursor: text;
        color: #333;
        padding-bottom: 2px; /* Add some bottom padding to create a visual cue */
        transition: background-color 0.3s ease; /* Add a smooth transition effect */
        text-decoration: underline; /* Add underline to the text */
        text-decoration-color: rgba(0, 0, 0, 0.2); /* Set the underline color */
        &:hover {
          // Styling for the hover effect
          background-color: #f5f5f5;
          border-radius: 3px;
        }
        
        &:focus {
          // Styling for when the input field is focused
          outline: none;
          box-shadow: 0 0 3px #999;
        }
    }

    &__Header:focus {
        outline: 0px;
        border: 0px;
        border-bottom: 1px solid #1890FF;
        box-shadow: none;
    }

    &__Header:hover {
        outline: 0px;
        border: 0px;
        border-bottom: 1px solid #1890FF;
        box-shadow: none;
    }

    &__Footer {
        width: 100%;
        display: flex;
        flex-direction: row-reverse;
        padding-top: 30px;
        gap: 16px;
    }

    &__Separator {
        margin: 16px 0px;
    }

    &__RowContainer {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    &__Row {
        display: flex;
        flex-direction: row;
        padding: 5px 0px;
        gap: 8px;
        width: 100%;
        height: 32px;
        cursor: pointer;
        padding-left: 12px;
    }

    &__Row:hover {
        background-color: #dbdbdb;
    }

    &__RowIcon {
        color: #096DD9;
    }

    &__RowText {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        color: #262626;
    }

    &__DisplaySelectedAttribute {
        display: flex;
        flex-direction: row;
        padding: 5px 12px;
        gap: 4px;
        width: 100%;
        height: 32px;
        background: #FFFFFF;
        border: 1px solid #D9D9D9;
        border-radius: 2px;
        align-items: center;
    }

    &__Icon {
        color: #096DD9;
    }
}