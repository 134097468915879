.intentionChart {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  grid-column: 1;

  @media (min-width: 760px) and (max-width: 1280px)  {
    grid-column-start: auto;
    grid-column-end: span 2;
    
  }

  @media (min-width: 1280px) and (max-width: 1366px) {
    grid-column-start: auto;
    grid-column-end: span 2;
    
  }
  @media (min-width: 1366px) and (max-width: 1820px) {
    grid-column-start: auto;
    grid-column-end: span 2;
  }

  @media (min-width: 1820px) and (max-width: 2560px) {
    grid-column-start: auto;
    grid-column-end: span 2; 
  }

}

.chartPlaceholder {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;

  @media (max-width: 768px) {
    padding: 10px;
  }
}
.chartHeader{
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #595959;

}