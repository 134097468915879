.UserProfile {
  width: 60%;
  margin: 0 auto;
  &__spacing {
    width: 100%;
  }

  &__mobile {
    display: none;
  }

  &__collapse {
    background-color: white !important;
    border: none !important;
    :global {
      .ant-collapse-item {
        border: 1px solid rgba(207, 216, 227, 0.5);
        border-radius: 8px !important;
        margin-bottom: 20px;
        box-shadow: 0px 0px 14px rgba(207, 216, 227, 0.25);
      }
      .ant-collapse-content-box {
        padding-top: 0 !important;
      }
      .ant-collapse-content-active {
        border: none;
        border-radius: 8px;
      }
      .ant-collapse-arrow {
        svg {
          font-size: 16px;
        }
      }
    }
  }

  &__layout {
    h3 {
      font-size: 18px;
      font-weight: 500;
      margin-bottom: 0;
    }
    p {
      margin-bottom: 0;
      font-size: 16px;
      font-weight: 400;
    }
  }
}
@media (max-width: 980px) {
  .UserProfile {
    width: 100%;

    &__collapse {
      padding: 0 10px !important;

      :global {
        .ant-collapse-content-box {
          padding: 0 !important;
        }
      }
    }

    &__mobile {
      display: block;
      background-color: white !important;
      border: none !important;

      :global {
        .ant-collapse-content {
          border-top: none !important;
        }
        .ant-collapse-content-box {
          padding-top: 0 !important;
        }
        .ant-collapse-header {
          padding-right: 15px !important;
        }
        .ant-collapse-item {
          border-bottom: none !important;
        }
        .ant-collapse-arrow {
          svg {
            font-size: 18px;
            color: #1890ff !important;
          }
        }
      }

      &__header {
        display: flex;
        background: rgba(9, 109, 217, 1);
        padding: 10px;

        &__icon {
          color: white;
          font-size: 20px;
          width: 10%;
          background: transparent;
          border: none;
        }
        &__text {
          width: 90%;
          margin: auto;
          padding-left: 10px;

          h3 {
            color: white;
            margin: auto 0;
          }
        }
      }

      &__profile {
        display: flex;
        width: 100%;
        padding: 15px;

        &__icon {
          width: 20%;
          font-size: 24px;
          margin: auto;
          color: #1890ff;
          text-align: flex-end;
          border: none;
          background: white;
        }
        &__back {
          background: rgba(9, 109, 217, 1);
        }

        &__pic {
          width: 80px;

          img {
            width: 100%;
            border-radius: 50%;
          }
        }

        &__text {
          width: 70%;
          text-align: left;
          padding: 10px;
          padding-left: 20px;
          margin: auto 0;

          p {
            margin-bottom: 0;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
          }
          h3 {
            margin-bottom: 0;
            font-size: 20px;
            font-weight: 500;
            line-height: 28px;
          }
        }
      }
    }

    &__collapse {
      &:first-child {
        background: white;
        border-radius: 0;
        box-shadow: none;
        border: none;
      }

      :global(.ant-collapse-item) {
        border: none !important;
      }
    }
  }
}
