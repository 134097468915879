.social-center p.small {
  font-size: 20px;
}
.social-center p.medium {
  font-size: 25px;
}
.social-center p.large {
  font-size: 30px;
}
.social-center p {
  font-weight: 600;
  margin-top: 5px;
}

@media (max-width: 1200px) {
  .socialsign-flex .ant-col-9 p {
    font-size: 15px;
    font-weight: 500px;
    padding-top: 7px;
  }
}

@media (max-width: 980px) {
  .socialsign-flex .ant-col-9 {
    max-width: 100%;
    text-align: center;
  }
  .socialsign-flex .ant-col-15 {
    max-width: 100%;
    margin: 30px auto;
  }
  .socialsign-flex {
    display: block;
  }
  .icon-section {
    row-gap: 23px;
    width: fit-content;
    margin: auto !important;
  }
}

.divider {
  border-top: 3px solid #d9d9d9;
}
.social-center {
  width: 70%;
  margin: auto;
}
/*****************************************************styling for paragraph of social media section***********************************************************************/
.social-center p {
  font-weight: 600;
  margin-top: 5px;
}
/***********************************************Styling for flex container of social media icons**********************************************************************************/
.ant-col-15 {
  justify-content: center;
  text-align: center;
}
/*****************************************************social media Button styling to provide background circular shape*****************************************************************/
.ant-btn-circle.ant-btn-lg {
  margin-top: 5px;
  border-style: none;
}
/********************************************************icon styling to make it center on the circular button***************************************************************************/
.ant-btn-circle.ant-btn-lg span {
  display: inline;
}
