.NameCell {
    display: flex;
    align-items: center;

    span {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;

        /* identical to box height, or 157% */

        /* Daybreak Blue/blue-6 */
        color: #1890FF;
        cursor: pointer;
    }

    &__img {
        margin-right: 10px;
        margin-bottom: 0px;
    }

    &__right {
        display: flex;
        flex-direction: column;

        &__btn {
            h6 {
                color: #1890FF;
                cursor: pointer;
                font-size: 14px;
            }
        }
    }

    &__Checkbox {
        margin-right: 10px;
    }
}