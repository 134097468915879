.defaultImagePosition {
    text-align: center;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    border: none;
    background-color: #fff;
    height: 100%;
    display: flex;
    justify-content: center;
  }