.User{
    :global .ant-avatar-sm {
        width: 16px;
        height: 16px;
        line-height: 16px;
    }
    &__user{
        margin: auto 0;
        margin-right: 10px;
    }
}