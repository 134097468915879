.AutomationRow {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;

    &__Header {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #8C8C8C;
    }

    &__Content {
        display: flex;
        width: 100%;
        gap: 20px;
        flex-wrap: wrap;
    }


}