.ErrorUi {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 15px;

    h1 {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 96px;
        line-height: 46px;
        text-align: center;
        color: #096DD9;
    }

    img {
        width: 243px;
    }

    p {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 22px;
        text-align: center;
        color: #595959;
    }

    button{
        width: 200px;
    }
}
.ErrorText{
    width: 100%;
    height: auto;
    background-color: #eeeef0;
    padding:10px 15px;

    &__Container{
        width:100%;
        text-align: left;
        font-family: Consolas,Monaco,'Andale Mono','Ubuntu Mono',monospace;
        word-break: break-all;
    }

    &__ButtonContainer{
        display: flex;
        flex-direction: row-reverse;
        width: 100%;
        button{
            margin-top:0px;
        }
    }
}