.AutomationActionBlock {
    display: inline-block;
    width: 100%;
    background: #FFFFFF;
    border: 1px solid #D9D9D9;
    border-radius: 4px;

    &__Body {
        display: flex;
        flex-direction: column;
        padding: 16px;
        gap: 16px;
        width: 100%;
        height: auto;

        &__FirstRow {
            display: flex;
            gap: 16px;
        }
    }

    &__Footer {
        width: 100%;
        height: 54px;
        padding: 16px 141px 16px 16px;
        gap: 16px;
        background: #FAFAFA;
        border-top: 1px solid #D9D9D9;
        border-radius: 0px 0px 0px 0px;
    }

    &__AddCondition {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        color: var(--primary-color);
        cursor: pointer;
    }
}

@media (max-width:768px){
    .AutomationActionBlock {
        display: inline-block;
        width: 100%;
        background: #FFFFFF;
        border: 1px solid #D9D9D9;
        border-radius: 4px;
    
        &__Body {
            display: flex;
            flex-direction: column;
            padding: 16px;
            gap: 16px;
            width: 100%;
            height: auto;
    
            &__FirstRow {
                display: flex;
                gap: 16px;
            }
        }
    
        &__Footer {
            width: 100%;
            height: 54px;
            padding: 10px;
            gap: 16px;
            background: #FAFAFA;
            border-top: 1px solid #D9D9D9;
            border-radius: 0px 0px 0px 0px;
        }
    
        &__AddCondition {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
            color: #096DD9;
            cursor: pointer;
        }
    }
    
}