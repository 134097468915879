.OnboardingPopover {
    &__Container {
        width: 100%;
        display: flex;
        justify-content: space-between;
        gap: 10px;
    }

    &__Content {}

    &__Footer {
        display: flex;
        justify-content: space-between;
        padding-top: 11px;
        align-items: center;
    }

    &__Progress {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        color: #262626;

        span {
            color: #8C8C8C;
        }
    }

    &__Button {}
}