.InvitePeople {
    &__desktopHeader {
        font-family: Inter;
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 32px;
        color: #000000;
    }

    &__mobileHeader {
        display: none;
    }

    :global {
        .ant-modal-header {
            border-bottom: none !important;
        }

        .ant-modal-title {
            font-size: 24px;
        }

        .ant-modal-title div {
            width: 100%;
        }

        .ant-modal-title div span {
            margin-left: 10px;
            font-size: 19.31px;
        }
    }
}

@media (max-width: 768px) {
    .InvitePeople {
        max-width: 100% !important;
        margin: 0;
        top: 0;
        width: 100% !important;
        height: 100%;
        padding-bottom: 0 !important;

        :global {
            .ant-modal-title div {
                width: 100%;
                // height: 50px;
            }
            .ant-modal-content {
                height: auto;

                .ant-modal-close-x {
                    display: none;
                }

                .ant-modal-body {
                    padding:25px;
                }
            }

            .ant-modal-header {
                padding: 0;
            }
        }

        &__desktopHeader {
            display: none;
        }

        &__mobileHeader {
            display: block;
            height: auto;
            padding: 10px;
            cursor: pointer;

        }
    }
}