.Table {
  display: flex;
  border-bottom: 1px solid #F0F0F0;
  padding: 20px;

  &__loader{
    display: flex;
width: 100%;
height: 100%;
position: absolute;
align-content: space-around;
top: 50%;
left: 50%;
  }
  
  &__table {
    overflow-y: auto;
    overflow-x: auto; //now
    // max-width: 100%;
    max-width: 1600px;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    max-height: 600px; /* Set the desired height */
    // overflow: auto;
    
      &::-webkit-scrollbar {
        width: 8px !important;  // For vertical scrollbar
        height: 8px !important; // For horizontal scrollbar
    }
    

    &::-webkit-scrollbar-thumb {
      background-color: #bfbfbf !important;
      border-radius: 4px !important;
    }

    &::-webkit-scrollbar-thumb:hover {
      background-color: #555 !important;
    }

    scrollbar-width: thin !important;
    scrollbar-color: #888 #eee !important;

    table{
    table-layout: fixed;

    }

    tbody,
    td,
    tfoot,
    th,
    thead,
    tr {
      padding: 1em 0;
    }

    td {
      padding: 1em;
      // background-color: white;
      border :1px solid #d9d9d9;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      
    }
    thead{  position: sticky;
      z-index: 1;
      width: fit-content;}


    tr:hover {
      background-color: #F0F0F0;
    }

    th:not(:first-child) {
      padding: 1em;
      background: #F5F5F5 !important;
      text-align: left;
      min-width: 250px;
      width: 250px;
      max-width: 250px;
    }
    th:nth-child(2) {
      position: sticky;
      z-index: 3;
      left: 44px !important;
  }
  /* Apply white background to td in columns corresponding to the first and second th */
table td:nth-child(1),
table td:nth-child(2) {
    // background-color: white;
    outline :1px solid #d9d9d9;

}
table td:nth-child(2) {
    // background-color: white;
    left: 44px !important;
    outline: 1px solid #d9d9d9;

}

  

    th {
      padding: 1em;
      background: #F5F5F5;
      text-align: left;
      max-width: 250px;
    }

    th:first-of-type {
      width: 50px;
      >div{
        background: none !important;
      }
    }

    tr {
      border-top: 1.5px solid #80808029;
      height: 54px;
    }

    thead {
      position: sticky;
      top: -1px;
      margin: 0 0 0 0;
    }

    tr:nth-child(odd) {
      background-color: #FFFFFF;
    }
  
    tr:nth-child(even) {
      background-color: #F9F9F9;
    }
  }

  thead{
    z-index: 1545454;

  }

  table thead th {
    position: sticky;
    top: 0;
  }
  
  table th,
  table td {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  

  &__footerbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__NewColumn {
    width: 54px;
    min-height: 100%;
    background-color: #fff;
    border-top: 1.5px solid rgba(128, 128, 128, 0.1607843137);
    border-right: 1px solid #F0F0F0;

    &__Header {
      padding: 1em;
      background: #F0F0F0;
      text-align: center;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      height: 54px;
      cursor: pointer;
      color: #595959;

      &:hover{
        background: #D9d9d9;
      }
    }
  }

  &__disablecolor {
    border: 0px;
  }



  &__oddrow {
    background-color: #F5F5F5;
  }

  &__oddrow:hover {
    background-color: #F0F0F0;
  }

  &__evenrow {
    background-color: #FFFFFF;
  }

  &__evencolor:hover {
    background-color: #F0F0F0;
  }

  &__oddcell {
    background-color: #F5F5F5;
  }

  &__evencell {
    background-color: #FFFFFF;
  }

}


// .table {
//   border: 1px solid #000;
//   max-width: 700px;
//   // overflow-x: auto;  // now
// }

// .header {
//   font-weight: bold;
// }

// .rows {
//   overflow-y: auto;
// }

// .row {
//   border-bottom: 1px solid #000;
//   height: 32px;

//   &.body {
//     :last-child {
//       border: 0;
//     }
//   }
// }

// .cell {
//   height: 100%;
//   line-height: 31px;
//   border-right: 1px solid #000;
//   /* padding-left: 5px; */

//   :last-child {
//     border: 0;
//   }
// }






//pagination
.pagination {
  display: flex;
  list-style: none;
  cursor: pointer;
  margin: 0;
}

.pagination a {
  padding: 4px 12px;
  margin: 0 5px;
  border-radius: 5px;
  // border: 1px solid black;
  color: black;
  text-decoration: none;
}

.pagination__link {
  font-weight: bold;
}

.pagination__link--active a {
  color: #fff !important;
  background: #f1b814;
  border: none;
}

.pagination__link--disabled a {
  color: rgb(198, 197, 202);
  // border: 1px solid rgb(198, 197, 202);
}

.pagination_container{
  margin: 0 auto;
  width: min-content;
}



/* Pagination Styles */
.pagination {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
  justify-content: center;
}

.pagination li {
  margin: 0 5px;
  cursor: pointer;
  font-size: 14px;
  // padding: 5px;
  border: 1px solid #ccc;
  background-color: #fff;
  border-radius: 5px;
  transition: background-color 0.3s, color 0.3s;
  height: 32px;
  display: flex;
}

.pagination li.active {
  border-color: var(--secondary-color);
  color: var(--secondary-color) !important;
}
.pagination li.disabled {
  background-color: #F5F5F5;
  a{
  color: grey !important;

  }
}

.pagination li:hover {
  background-color: #ccc;
  color: #000;
}

.second-column span{
  color: var(--secondary-color) !important;
}