.Login {
  width: 100%;
  margin: 0 auto;
  padding: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  &__signInContainer {
    width: 85%;
    margin: auto;
    h3 {
      text-align: left;
      margin-bottom: 10px;
      margin-top: 30px;
      padding: 20px 0px 0px 0px;
      font-size: 25px;
      font-weight: 500;
    }
    &__formItem {
      display: flex !important;
      flex-direction: column !important;
      font-size: 14px;
      font-weight: 400;
      color: #a9a9a9;

      &__error {
        color: red;
        height: 0;
        font-size: 12px !important;
        line-height: 14px;
      }
      a {
        float: right;
        cursor: pointer;
      }
    }

    &__btnContainer {
      margin: 0 auto;
      width: 100%;
      text-align: center;
      margin-top: 20px;
      margin-bottom: 20px;
      Button {
        min-width: 225px;
        height: 40px;
        color: white;
        border-radius: 3px;
        span {
          font-size: 16px;
          font-weight: 400;
        }
        span {
          margin-left: 10px;
          font-size: 15px;
        }
      }
    }
    &__btn_pwd_link {
      margin: 0 auto;
      width: 100%;
      text-align: center;
      margin-top: -55px;
      Button {
        min-width: 225px;
        height: 40px;
        color: white;
        border-radius: 3px;
        span {
          font-size: 16px;
          font-weight: 400;
        }
        span {
          margin-left: 10px;
          font-size: 15px;
        }
      }
    }
    &__helpMsg {
      font-size: 14px;
      font-weight: 400;
      color: #a9a9a9;
      padding-bottom: 32px;

      a {
        cursor: pointer;
      }
    }
  }
  &__alert {
    margin-top: 15px;
  }
  &__btn_back_container {
    margin: 0 auto;
    width: 100%;
    text-align: center;
    margin-top: 40px;
    Button {
      // margin-top: 145px;
      min-width: 200px;
    }
  }
  &__message_container {
    padding-top: 20px;
    margin-bottom: 20px;
  }
  // &__btn_back {
  //   margin-top: 145px;
  //   width: 50%;
  // }

  footer {
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    padding-top: 0px;
    padding-bottom: 10px;
    padding-bottom: 65px;

    span {
      color: #706e6e;
    }
  }
}

@media only screen and (min-width: 980px) and (max-width: 1050px) {
  .Login {
    &__signInContainer {
      &__formItem {
        padding-top: 20px;
        &__check_remember {
          width: 100%;
          justify-content: center;
        }
        &__forget_link {
          width: 100%;
          text-align: center;
        }
      }
    }
  }
}
