.ListAutomationRule {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 16px;
    gap: 32px;
    width: 1200px;

    &__Header {
        width: 100%;

        h4 {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            line-height: 28px;
            color: #000000;
        }

        p {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
            letter-spacing: 0.0025em;
            color: #424242;
        }
    }

    &__Container {
        display: flex;
        flex-direction: row;
        width: 100%;
        background: #FFFFFF;
        border: 1px solid #D9D9D9;
        border-radius: 8px;
        border: 1px solid #D9D9D9;
        border-radius: 8px;
        padding: 32px;
        overflow: auto;
        position: relative;

        &__DisabledOverlay {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(255, 255, 255, 0.5);
            z-index: 10;
          }

        &__Sidebar {
            width: 200px;
            height: 100%;
            padding-right: 32px;

        }

        &__Content {
            flex: 1;
            overflow: auto;
            display: flex;
            flex-direction: column;
            gap: 20px;
        }
    }
}

.AutomationRow {
    padding: 10px;
    gap: 10px;
    width: 100%;
    height: 44px;
    background: #FFFFFF;
    border-radius: 4px;
}

.AutomationRow:hover {
    background: #E6F7FF;
    cursor: pointer;
}

.Content {
    &__Header {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        padding-left: 0px;

        p {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            margin-bottom: 0px;
            color: #000000;
        }

        &__Input {
            padding: 6px 16px;
            gap: 8px;
            width: 347px;
            height: 34px;
            background: #FFFFFF;
            border: 1px solid #D9D9D9;
            border-radius: 2px;
        }
    }
}

@media (max-width:768px){
    .ListAutomationRule {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 16px;
        gap: 32px;
        width: auto;
    
        &__Header {
            width: 100%;
    
            h4 {
                font-family: 'Inter';
                font-style: normal;
                font-weight: 600;
                font-size: 20px;
                line-height: 28px;
                color: #000000;
            }
    
            p {
                font-family: 'Inter';
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 19px;
                letter-spacing: 0.0025em;
                color: #424242;
            }
        }
    
        &__Container {
            display: flex;
            flex-direction: row;
            width: 100%;
            background: #FFFFFF;
            border: 1px solid #D9D9D9;
            border-radius: 8px;
            border: 1px solid #D9D9D9;
            border-radius: 8px;
            padding: 10px 15px;
            overflow: auto;
    
            &__Sidebar {
                width: 200px;
                height: 100%;
                padding-right: 32px;
    
            }
    
            &__Content {
                flex: 1;
                overflow: auto;
                display: flex;
                flex-direction: column;
                gap: 20px;
            }
        }
    }
    
    .AutomationRow {
        padding: 10px;
        gap: 10px;
        width: 100%;
        height: 44px;
        background: #FFFFFF;
        border-radius: 4px;
    }
    
    .AutomationRow:hover {
        background: #E6F7FF;
        cursor: pointer;
    }
    
    .Content {
        &__Header {
            display: flex;
            width: 100%;
            justify-content: space-between;
            align-items: center;
            padding-left: 0px;
    
            p {
                font-family: 'Inter';
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 24px;
                margin-bottom: 0px;
                color: #000000;
            }
    
            &__Input {
                padding: 6px 16px;
                gap: 8px;
                width: 347px;
                height: 34px;
                background: #FFFFFF;
                border: 1px solid #D9D9D9;
                border-radius: 2px;
            }
        }
    }
    
}