.SettingsPage {
  height: 100%;
  width: 100%;
  display: flex;

  &__left_container {
    flex: 1;
    height: 100%;
    padding: 0 20px;
    display: flex;
    flex-direction: column;
  }

  &__right_container {
    flex: 3;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 25px;
    background: #fafafa;
  }
}

@media (max-width:768px){
  .SettingsPage {

    &__left_container {
      padding: 0px;
      flex: 0;
    }
  
    &__right_container {
      padding: 10px 7px;
    }
  }
}