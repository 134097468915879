.App {
  overflow: auto;
  height: 100%;

  &::-webkit-scrollbar {
    width: 8px !important;
    height: 8px !important;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #bfbfbf !important;
    border-radius: 4px !important;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #555 !important;
  }

  scrollbar-width: thin !important;
  scrollbar-color: #bfbfbf #eee !important;
}