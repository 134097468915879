.public-DraftEditorPlaceholder-root{
    font-size: 14px;
    font-family: 'Inter';
    font-weight: 400;
    color: #c9c5c5;
  }
  .public-DraftEditorPlaceholder-readonly {
    font-size: 14px;
    font-family: 'Inter';
    font-weight: 400;
    color: #5c5252;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
  }
