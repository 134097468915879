.ConversationContainer {
  height: 100%;
  display: flex;
  // background-color: #1890ff;


  &__main,
  &__sidebar,
  &__loader,
  .RichTextEditor__editor___1QqIU,
  &__rich_text_editor_simple {
    &::-webkit-scrollbar {
      width: 8px;
      margin: 0;
      /* or adjust as necessary */
      position: relative;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #bfbfbf;
      border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background-color: #bfbfbf;
    }

    scrollbar-width: thin;
    scrollbar-color: #bfbfbf #eee;
  }

  &__main {
    padding: 0px 64px 64px 64px;
    overflow-x: hidden;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    width: 100%;

    &::-webkit-scrollbar {
      width: 8px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #bfbfbf;
      border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background-color: #bfbfbf;
    }

    scrollbar-width: thin;
    scrollbar-color: #bfbfbf #eee;
  }

  &__sidebar {
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    height: 100%;
    background: #f5f5f5;
    width: 360px;
    padding: 20px;

    &::-webkit-scrollbar {
      width: 8px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #bfbfbf;
      border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background-color: #bfbfbf;
    }

    scrollbar-width: thin;
    scrollbar-color: #bfbfbf #eee;

    h1 {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: #595959;
      margin-bottom: 16px;
    }

    &__loader {
      padding: 12px;
      overflow-y: auto;
      background: #ffffff;
      border: 1px solid #f0f0f0;
      border-radius: 4px;
      display: flex;
      justify-content: center;
      margin-top: 20px;
      margin-bottom: 20px;
    }

    &__body {
      display: flex;
      flex-direction: column;
    }
  }

  &__emptychannels {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 83%;

    &__Heading {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 32px;
      text-align: center;
      color: #262626;
      padding-top: 50px;
    }

    &__Placeholder {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      width: 600px;
      text-align: center;
      margin-bottom: 40px;
      color: #8C8C8C;
    }

    &__ButtonContainer {
      display: flex;
      gap: 16px;
    }
  }

  &__emptychat {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 83%;

    span {
      font-family: Inter;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      margin-top: 58px;
    }
  }

  &__inputcontainer {
    &__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: max-content;

    }

    :global {
      .RichTextEditor__root___2QXK- {
        border-color: transparent !important;
        border-radius: 0 !important;

        :global .RichTextEditor__editor___1QqIU {
          font-size: 14px !important;
          height: 65px;
        }
      }
    }

    .defaultBackground {
      background-color: white;
    }


    &__rich_text_editor_advanced {
      height: 210px;

      :global {
        .RichTextEditor__editor___1QqIU {
          height: 152px;
          overflow-y: auto;
        }
      }
    }

    &__rich_text_editor_simple {
      max-height: 150px;
      overflow: scroll;
    }

    &__input_toolbar_visible {
      display: block;
    }

    &__input_toolbar_hide {
      display: none;
    }

    &__edit_options_container {
      margin-top: 5px;
    }

    &__input {
      padding: 16px 12px !important;
      border: none;
    }

    &__input:focus {
      box-shadow: none;
    }

    &__inputfilecontainer {
      display: flex;
      flex-direction: column;
      border-radius: 2px;
      background-color: transparent;
    }

    &__draginputfilecontainer {
      border: 1px solid #1890ff;
      box-shadow: 0px 0px 4px rgba(24, 144, 255, 0.5);
      border-radius: 2px;
      background-color: transparent;
    }

    &__draginput {
      display: none;
    }

    /* &__inputfilecontainer:focus-within {
    //   border: 2px solid #40a9ff;
    // }
    */

    &__optionsbar {
      display: flex;
      justify-content: space-between;
      padding: 13.82px 18px;
      margin-bottom: 15px;
      border-top: 0.2px solid #d9d9d970;
      background-color: white;
      
    }

    &__editor_validation_container {
      background: #ffefeebf;
      border-bottom-left-radius: 3px;
      border-bottom-right-radius: 3px;
      padding: 2px 10px;
      border-color: red;
      border-bottom: solid;
      color: red;
    }
  }

  .yellowBackground {

    background: #FFFBE6;
  }

  .yellow-tab-text {
    color: yellow;
  }

  &__FileListContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    background-color: #fff;
    padding-left: 10px;
    padding-bottom: 10px;
  }

  &__PlaceHolder {
    height: 56px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    text-align: center;
    line-height: 56px;
    color: #1890ff;
    background-color: #fff;
  }

  &__icons {
    display: flex;
    padding: 8px;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    border-radius: 2px;
    border: 1px solid var(--gray-gray-5, #D9D9D9);
    // background: var(--gray-gray-3, #F5F5F5);
  }

  &__text_editor_wrapper {
    border: 2px solid #875dbe3d;
    border-radius: 5px;
    border-bottom-width: medium;
    border-bottom-color: #875dbe;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;


    &__text_area {
      background-color: #fff;
      height: 150px !important;
      border: 1px solid #cfc6dc !important;
      padding: 5px !important;
      border-radius: 2px !important;
    }
  }

  &__iconsleft {
    font-size: 20px;
    cursor: pointer;
    margin-right: 10px;
    color: #8c8c8c !important;
  }

  &__active_icon {
    color: blue !important;
  }
}

.TabRow {
  margin-left: 20px ;
  display: flex;
  flex-direction: row;
  cursor: pointer;
}

.my-tabs-container .ant-tabs-ink-bar {
  color: #faad14 !important;
}

.my-tabs-container.chatChoice-1 .ant-tabs-ink-bar {
  background: black !important;
}

html>body>div:nth-child(1)>div>div>div:nth-child(2)>div:nth-child(2)>main>div:nth-child(1)>div>div:nth-child(2)>div:nth-child(4)>div>div:nth-child(2)>div:nth-child(1)>div:nth-child(1)>div>div:nth-child(1)>div:nth-child(1)>div>div:nth-child(3) .ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
  /* Your styles here */
  color: magenta;
}

.ProgressContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

/* in your .scss or .css file */
.greenBackground {
  display: flex;
  padding: 8px;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  border-radius: 2px;
  background: var(--daybreak-blue-blue-6, #1890FF); 
  border-style: none;
}
.greenBackground:hover {
  // background: var(--daybreak-blue-blue-6, #1890FF);
}

.yellowBackground1 {
  display: flex;
  padding: 8px;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  border-radius: 2px;
  // background: var(--calendula-gold-gold-6, #FAAD14);
  border-style: none;
  /* example yellow color for the Send button */
}

.editorGreenBorder {

  border: 2px solid #00FF00;
  /* Replace with the exact green color you're using */
}


.inputGreenBorder {
  padding-bottom: 10px;
  border-radius: 2px;
  // border: 1px solid var(--daybreak-blue-blue-6, #1890FF);
  background: var(--gray-gray-1, #FFF);
  box-shadow: 0px 0px 4px 0px rgba(165, 172, 179, 0.5);
  /* Replace with the exact green color you're using */
}

.inputYellowBorder {
  border-radius: 2px;
  // border: 1px solid rgb(229 231 235);
  background: #FFFBE6;
  box-shadow: 0px 0px 4px 0px rgb(229 231 235);
}

:global(.my-tabs-container.activeTabChat .ant-tabs-ink-bar) {
  background-color: #1890FF !important;
}

:global(.my-tabs-container.activeTabNote .ant-tabs-ink-bar) {
  // background-color: #FAAD14 !important;  
}

@media (min-width: 1280px) and (max-width: 1920px) {
  .ConversationContainer {
    display: flex;

  &__main {
    padding: 0px 24px 24px 24px;
    overflow-x: hidden;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 96vh;

    &::-webkit-scrollbar {
      width: 8px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #bfbfbf;
      border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background-color: #bfbfbf;
    }

    scrollbar-width: thin;
    scrollbar-color: #bfbfbf #eee;
  }

}
}
@media (min-width: 768px) and (max-width: 1280px) {
  .ConversationContainer {
    display: flex;

  &__main {
    padding: 0px 24px 24px 24px;
    overflow-x: hidden;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 96vh;


    &::-webkit-scrollbar {
      width: 8px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #bfbfbf;
      border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background-color: #bfbfbf;
    }

    scrollbar-width: thin;
    scrollbar-color: #bfbfbf #eee;
  }

}
}
@media (min-width: 1910px) and (max-width: 2560px) {
  .ConversationContainer {
    display: flex;

  &__main {
    padding: 0px 60px 24px 60px;
    overflow-x: hidden;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 96vh;

    &::-webkit-scrollbar {
      width: 8px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #bfbfbf;
      border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background-color: #bfbfbf;
    }

    scrollbar-width: thin;
    scrollbar-color: #bfbfbf #eee;
  }

}
}


@media (max-width: 768px) {
  .ConversationContainer {
    padding: 5px 10px;
    background: white;

    &__main {
      padding: 0px;
    }

    &__inputcontainer {
      position: absolute;
      bottom: 49px;
      width: 94%;

      &__header {
        :global .ant-tabs-tab+.ant-tabs-tab {
          margin: 0 0 0 10px;
          background: #faad14 !important;
        }
      }
    }
  }
}