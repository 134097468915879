.SegmentationModal {
    :global .ant-modal-body {
        padding: 40px;
        display: flex;
        flex-direction: column;
    }

    &__body {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        h3 {
            font-size: 16px;
            font-weight: bold;
            margin-bottom: 10px;
        }
        p{
            color: grey;
        }
    }

    &__footer {
        margin-left: auto;
        button:first-child {
            margin-right: 8px;
          }
    }
}