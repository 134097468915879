.ListFile {
    padding: 0px 12px 0px 4px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 50px;
    width: 250px;
    background: #F0F0F0;
    box-shadow: 0px 3px 6px -4px rgba(0, 0, 0, 0.12);
    border-radius: 2px;
    overflow: hidden;
    margin-right: 10px;
    margin-bottom: 10px;

    &__ImageContainer {
        display: flex;
        align-items: center;
        width: 50px;
        height: 100%;
    }

    &__NameContainer {
        display: flex;
        align-items: center;
        flex: 1;
        height: 100%;
        white-space: nowrap;
        overflow: hidden;
    }

    &__CloseContainer {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: 50px;
        height: 100%;
    }

    &__Icons {
        font-size: 20px;
        cursor: pointer;
        color: #262626 !important;
    }
}