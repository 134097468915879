.InterfaceOptionsPage {
    height: 100%;
    width: 100%;
    &__header {
      display: flex;
      flex-direction: column;
      border-bottom: 1px solid #f0f0f0;
  
      h3 {
        margin-bottom: 4px;
        font-weight: 600;
        font-size: 20px;
        line-height: 28px;
        font-family: "Inter";
        font-style: normal;
      }
      p {
        margin-top: 0;
        margin-bottom: 30px;
  
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: 0.0025em;
  
        color: #424242;
      }
    }
  
    &__body {
      &__container {
        width: 85%;
        margin-top: 20px;
        padding: 0px 42px;
        background-color: white;
        position: relative;

        &__DisabledOverlay {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(255, 255, 255, 0.5);
          z-index: 10;
        }
      }
    }
  }
  @media (max-width:768px){
    .InterfaceOptionsPage {
    height: 100%;
    width: 100%;
    &__header {
      display: flex;
      flex-direction: column;
      border-bottom: 1px solid #f0f0f0;
  
      h3 {
        margin-bottom: 4px;
        font-weight: 600;
        font-size: 20px;
        line-height: 28px;
        font-family: "Inter";
        font-style: normal;
      }
      p {
        margin-top: 0;
        margin-bottom: 30px;
  
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: 0.0025em;
  
        color: #424242;
      }
    }
  
    &__body {
      &__container {
        padding: 5px;
      }
    }
  }
  }
  