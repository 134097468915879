.ContactDrawerAttributeButton {
    display: flex;
    gap: 8px;

    &__NewAttributeButton {
        flex: 1;
        display: flex;
        height: 32px;
        background: #FFFFFF;
        border: 1px dashed #D9D9D9;
        border-radius: 2px;
        align-items: center;
        padding: 5px 16px;
        gap: 10px;
        justify-content: center;
        align-items: center;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        color: #262626;
        cursor: pointer;



        p {
            margin-bottom: 0px;
        }
    }

    &__SearchTrigger {
        display: flex;
        align-items: center;
        padding: 8px;
        gap: 8px;
        width: 32px;
        height: 32px;
        background: #FFFFFF;
        border: 1px solid #D9D9D9;
        border-radius: 2px;
        cursor: pointer;
        overflow: hidden;
    }

    &__Expand {
        flex: 1;
        padding-left: 0px;
    }

    &__Collapse {
        flex: 0;
        width: 32px;
    }

    &__Input {
        padding: 5px 12px;
        gap: 10px;
        height: 32px;
        background: #FFFFFF;
        border: 1px solid #1890FF;
        box-shadow: 0px 0px 4px rgba(24, 144, 255, 0.5);
        border-radius: 2px 0px 0px 2px;
        width: 50px;
        flex: 1;
        outline: 0px;
        border: 0px;
        box-shadow: none;
        border-right: 1px solid #D9D9D9;
    }
}