.EditableTagGroup {
    display: flex !important;
    flex-wrap: wrap;

    :global .ant-tag {
        height: 24px;
    }

    &__tagElem {
        display: inline-block;
    }

    &__input {
        width: 78px;
    }
}

@media (max-width: 768px) {
    .EditableTagGroup {
        width: 100%;
        justify-content: center;
        text-align: center;

        :global .ant-tag {
            height: 40px;
            width: 80%;
            font-size: 16px;
            font-weight: 400;
            padding-top: 8px;
            background: white;
            margin-top: 16px;

            .ant-tag-close-icon {
                font-size: 12px;
            }
        }

        &__tagElem {
            margin-left: 5px;

            :global .ant-tag {
                width: 100%;
            }
        }

        &__input {
            width: 80%;
            height: 40px;
            font-size: 16px;
            margin-top: 10px;
        }
    }
}