.disabled {
  color: #8c8c8c !important;
}
.Timezonebody {
  :global {
    .css-b62m3t-container {
      width: 308px;
      border-radius: 2px;
    }
    .css-1okebmr-indicatorSeparator {
      width: 0px;
    }
    .css-3iigni-container {
      width: 308px;
      border-radius: 2px;
    }
    .css-1insrsq-control {
      border-radius: 2px;
      background: #f5f5f5;
      border: 1px solid #d9d9d9;
    }
    .css-19ktvh3-Sa {
      color: #8c8c8c;
    }
    .css-b62m3t-container {
      width: 308px;
      background-color: #797979;
    }
    .css-1s2u09g-control {
      border: 1px solid #d9d9d9;
      border-radius: 2px;
      height: 30px;
    }

    .css-89lswr-Sa {
      display: unset;
    }
    .css-17adf03-Sa {
      padding: 0px 8px 0px 8px;
    }
  }

  margin-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px 40px;
  gap: 16px;
  width: 65%;
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  background-color: white;

  &__first_container {
    display: flex;
    flex-direction: column;
    position: relative;

    &__DisabledOverlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(255, 255, 255, 0.5);
      z-index: 10;
    }
    h4 {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: #000000;
      margin-bottom: 0;
    }
    &__disabled_div {
      margin-top: 24px;
      display: flex;
      align-items: flex-end;
      flex-direction: column;
      justify-content: space-between;
      &__msg {
        width: 308px;
        margin-top: 8px;
        display: flex;
        background: var(--secondary-color);
        font-style: normal;
        font-weight: 400;
        font-size: 11px;
        line-height: 20px;
        padding: 4px 0px 4px 6px;
        img {
          margin-right: 4px;
        }
      }
    }
    &__inner_div {
      margin-top: 24px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      &__info {
        display: flex;
        align-items: center;
        p {
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 22px;
          color: #262626;
          margin-top: 0;
          margin-bottom: 0;
          margin-right: 5px;
        }
      }
    }
  }
}

.language {
  position: relative;
  h4{
    font-size: 16px;
  }

    &__DisabledOverlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(255, 255, 255, 0.5);
      z-index: 10;
    }
  &__container{
    display: flex;
    justify-content: space-between;

    &__label{
      display: flex;

      img{
        width: 15px;
        margin-right: 10px;
        margin-top: -5px;
      }

      p{
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        color: #262626;
        margin-top: 0;
        margin-bottom: 0;
        margin-right: 5px;
      }
    }
  }
}


@media (max-width:768px){
  .disabled {
    color: #8c8c8c !important;
  }
  .Timezonebody {
    :global {
      .css-b62m3t-container {
        width: 308px;
        border-radius: 2px;
      }
      .css-1okebmr-indicatorSeparator {
        width: 0px;
      }
      .css-3iigni-container {
        width: 308px;
        border-radius: 2px;
      }
      .css-1insrsq-control {
        border-radius: 2px;
        background: #f5f5f5;
        border: 1px solid #d9d9d9;
      }
      .css-19ktvh3-Sa {
        color: #8c8c8c;
      }
      .css-b62m3t-container {
        width: 308px;
        background-color: #797979;
      }
      .css-1s2u09g-control {
        border: 1px solid #d9d9d9;
        border-radius: 2px;
        height: 30px;
      }
  
      .css-89lswr-Sa {
        display: unset;
      }
      .css-17adf03-Sa {
        padding: 0px 8px 0px 8px;
      }
    }
  
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 10px 15px;
    gap: 16px;
    width: 85%;
    border: 1px solid #d9d9d9;
    border-radius: 8px;
    background-color: white;
  
    &__first_container {
      display: flex;
      flex-direction: column;
      h4 {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #000000;
        margin-bottom: 0;
      }
      &__disabled_div {
        margin-top: 24px;
        display: flex;
        align-items: flex-end;
        flex-direction: column;
        justify-content: space-between;
        &__msg {
          width: 308px;
          margin-top: 8px;
          background: var(--secondary-color);
          font-style: normal;
          font-weight: 400;
          font-size: 11px;
          line-height: 20px;
          padding: 4px 0px 4px 6px;
          img {
            margin-right: 4px;
          }
        }
      }
      &__inner_div {
        margin-top: 24px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
  
        &__info {
          display: flex;
          align-items: center;
          p {
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
            color: #262626;
            margin-top: 0;
            margin-bottom: 0;
            margin-right: 5px;
          }
        }
      }
    }
  }
  
  .language {
    h4{
      font-size: 16px;
    }
    &__container{
      display: flex;
      justify-content: space-between;
  
      &__label{
        display: flex;
        img{
          width: 15px;
          margin-right: 10px;
          margin-top: -5px;
        }
  
        p{
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 22px;
          color: #262626;
          margin-top: 0;
          margin-bottom: 0;
          margin-right: 5px;
          }
      }
    }
  }
  
}