.header {
  display: flex;
  padding: 16px 24px;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  background-color: #FFFFFF;
  border-bottom: 1px solid #D9D9D9;
  position: sticky;
  top: 0;
  z-index: 1000;

  @media (max-width: 768px) {
    flex-direction: row;
    align-items: center;
    padding: 16px;
  }
}

.header div {
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  color: #262626;

  @media (max-width: 768px) {
    font-size: 18px;
    line-height: 26px;
  }
}

.menu-icon {
  display: none;
  
  @media (max-width: 768px) {
    display: block;
    cursor: pointer;
  }
}

.range-picker-desktop {
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    display: none;
  }
}

.range-picker-mobile {
  display: none;
  justify-content: end;
  margin-top: 20px;

  @media (max-width: 768px) {
    display: flex;
    margin-top: 10px;
  }
}

.dropdown-menu {
  display: none;
  flex-direction: column;
  width: 100%;
  margin-top: 10px;
  background-color: #FFFFFF;
  border: 1px solid #D9D9D9;

  @media (max-width: 768px) {
    display: flex;
  }
}

.show {
  display: flex;
}
