.CreateOrganization {
    width: 100%;
    padding: 80px 20px;

    &__Wrapper {
        margin-bottom: 0px;
    }

    &__Input {
        margin-bottom: 16px;
    }

    &__SubmitButton {
        width: 100%;
    }

    &__formContainer {
        width: 65%;
        margin: 0 auto;
        box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
        border-radius: 8px;
    }

    &__Header {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 64px 90px 0px 90px;
        gap: 4px;

        &__Title {

            font-family: 'Inter';
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: #616161;
        }
    }
}

@media (max-width: 980px) {
    .CreateOrganization {
        padding: 0px;

        &__formContainer {
            width: 100%;
            height: 100vh;
            margin: 0;
            border-radius: 0;
            box-shadow: none;

            &__fullsignup {
                display: block !important;
                width: 100%;

                &__carousel {
                    height: 100vh;
                    max-width: 100% !important;
                }

                :global {
                    .ant-col-12 {
                        max-width: 100% !important;
                    }
                }
            }
        }
    }
}