.CannedTemplateVariables {
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 0px;
    gap: 8px;
    height: 144px;

    &__Header {
        display: flex;
        justify-content: space-between;
        width: 100%;
        align-items: center;

        &__Title {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: #262626;
            margin-bottom: 12px;
        }
    }

    &__Container {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding: 8px;
        gap: 8px;
        width: 100%;
        height: 104px;
        background: #FFFFFF;
        flex-wrap: wrap;
        border: 1px solid #D9D9D9;
        border-radius: 4px;
        overflow-y: auto;


        span {
            cursor: pointer;
        }
    }
}