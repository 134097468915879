.NormalAttributeInlineEditingField {
    margin-bottom: 10px;
    // border: 0.1px solid #d3d3d385;
padding: 10px;
&:hover {
    .NormalAttributeInlineEditingField__IconContainer {
      display: flex; // Make the info icon container visible on hover
    }
  }
    &__DeleteUnderline {
        border-bottom: 1px solid #F5222D;
    }

    &__PlaceholderText {
        padding-top: 5px;
        padding-left: 10px;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
    }

    &__AddMore {
        display: flex;
        gap: 6px;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        color: #8c8c8c;
        align-items: center;
        padding-top: 10px;
        cursor: pointer;
    }

    &__AddMore :hover{
        color: #1890FF;
    }

    &__InputField {
        outline: 0px;
        border: 0px;
        padding: 10px 0px !important;
        color: #262626;
        // border-bottom: 1px solid #8C8C8C;
        box-shadow: none;
        &::placeholder {
            color: #bfbfbf !important; // Override placeholder text color
          }
        // :hover{
        // border-bottom: 1px solid #8C8C8C;
        // }
        :global .ant-select-selection-placeholder {
            color: #bfbfbf !important;
          }
          
        :global .ant-input::placeholder {
            color: #bfbfbf !important;
        }
    }

    &__InputField:focus,
    &__InputField:hover,
    &__InputField:active {
        border-bottom: 1px solid #D9D9D9;
        box-shadow: none;
    }

    &__InputField:focus{
        border-bottom: 1px solid #1890FF;
    }


    &__IconContainer {
        display: none;
        gap: 10px;
        color: #8C8C8C;

    }

    &__IconContainer:hover {
        display: flex;
        gap: 10px;
        color: 
    #1890FF
        ;

    }

    &__AttributeContainer {
        display: flex;
        justify-content: space-between;

    }

    &__FieldContainer {
        display: flex;
        flex-direction: column;
min-height: 31px;


        &__box {
            display: flex;
            flex-direction: column;
            ;
            overflow: hidden;
        }

        &__FieldTrigger {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
            color: #262626;
        }
    }

    &__Label {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        color: #808080;
        cursor: pointer;
    }

    &__DeleteIcon:hover {
        color: #CF1322;
        cursor: pointer;
    }

    &__InfoIcon:hover {
        color: #1890ff;
        cursor: pointer;
    }
}

.ErrorMessage {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #F5222D;
    padding-bottom: 5px;
}