.ContactDrawerAccordion {
    width: 100%;
    margin: 21px 0px;
    transition: height 0.3s ease; // Specify the transition property, duration, and easing

    .open {
        height: auto; // Set the height to 'auto' when the accordion is open
    }

    &__Header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 8px;
        gap: 8px;
        width: 100%;
        height: 40px;
        background: #F5F5F5;
        border-bottom: 1px solid #F0F0F0;

        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 157.15%;
        color: #262626;

        &__Title {}
    }

    &__Body {
        padding-top: 0px;
        overflow: hidden;
        width: 100%;
        height: 0px;
    }

    &__FullWidth {
        height: auto;
        padding-top: 10px;
        padding-bottom: 21px;
        overflow: auto;
        border-bottom: 1px solid #F0F0F0;
        // max-height: 450px;
    }
}