// :global .ant-modal {
//   width: 650px !important;
//}

.ChannelsBody {
  height: 100%;
  overflow-y: scroll;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 33px 28px;
  max-width: 1076px;
  min-height: 372px;
  background: #ffffff;
  border: 1px solid #d9d9d9;
  border-radius: 8px;

  &::-webkit-scrollbar {
    width: 8px !important;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #bfbfbf !important;
    border-radius: 4px !important;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #555 !important;
  }

  scrollbar-width: thin !important;
  scrollbar-color: #888 #eee !important;

  h3 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
    letter-spacing: 0.2px;
    margin-bottom: 0px;
  }

  &__Header {
    font-family: "Inter";
    font-style: normal;
    color: #000000;
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    letter-spacing: 0.2px;
    margin-bottom: 20px;

    &__search {
      span {
        svg {
          height: 12.5px;
          fill: #262626;
        }
      }
    }
  }

  .ChannelCards {
    margin-top: 0px;
    margin-bottom: 40px;
    display: flex;
    flex-wrap: wrap;
    column-gap: 28px;
    row-gap: 28px;
    width: 100%;

    .ChannelCard {
      max-width: 502px;
      font-family: "Inter";
      font-style: normal;
      flex: 45%;
      padding: 20px;
      background: #ffffff;
      border: 1px solid #d9d9d9;
      border-radius: 4px;

      &__header {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;

        &__name {
          display: flex;
          flex-direction: column;
          height: max-content;

          p {
            font-weight: 400;
            font-size: 12px;
            margin-bottom: 0px;
            color: #595959;
            margin-top: 6px;
          }

          div {
            background: #f5f5f5;
            border-radius: 4px;
            width: 39.88px;
            height: 40px;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 6px;

            img {
              height: 30px;
              width: 30px;
            }

            svg {
              height: 30px;
              width: 30px;
            }
          }
        }

        &__status {
          :global .ant-switch-checked {
            background-color: #096dd9;
          }

          margin-top: 8px;
          display: flex;
          align-items: center;

          div {
            display: flex;
            align-items: center;
            margin-right: 16px;

            img {
              height: 16px;
              width: 16px;
            }

            p {
              font-weight: 400;
              font-size: 14px;
              line-height: 22px;

              margin-bottom: 0px;
              margin-top: 0px;
              margin-left: 4px;
            }
          }
        }
      }

      &__body {
        border-bottom: 1px solid #f0f0f0;
        font-weight: 600;
        font-size: 20px;
        line-height: 28px;
        color: #262626;
        padding-bottom: 15px;

        p {
          margin-top: 0;
          margin-bottom: 0;
        }
      }

      &__footer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 15px;

        p {
          font-weight: 400;
          font-size: 14px;
          line-height: 22px;
          color: #262626;
          margin-bottom: 0;
          margin-top: 0;
        }

        &__dropdown {
          display: flex;
          align-items: center;

          p {
            margin-left: 4px;
            margin-right: 12px;
          }
        }
      }
    }
  }

  .ChannelSections {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    width: 100%;

    &__section {
      font-family: "Inter";
      font-style: normal;
      margin-bottom: 24px;

      &__header {
        margin-bottom: 16px;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #8c8c8c;
      }

      &__body {
        display: flex;
        flex-wrap: wrap;
        column-gap: 20px;
        row-gap: 20px;
        width: 100%;

        &__card {
          cursor: pointer;
          max-width: 240px;
          font-family: "Inter";
          font-style: normal;
          flex: 50%;
          padding: 24px 20px;
          background: #ffffff;
          border: 1px solid #d9d9d9;
          border-radius: 4px;
          display: flex;
          flex-direction: column;
          align-items: center;

          svg {
            width: 40px;
            height: 40px;
          }

          img {
            width: 40px;
            height: 40px;
          }

          h4 {
            margin-top: 12px;
            margin-bottom: 0;
            font-weight: 500;
            font-size: 14px;
            line-height: 22px;
            color: #000000;
          }

          p {
            font-weight: 400;
            font-size: 12px;
            line-height: 20px;
            text-align: center;
            color: #595959;
            margin-top: 4px;
            margin-bottom: 16px;
          }
        }

        &__card:hover {
          box-shadow: rgba(204, 204, 204, 0.2) 0px 7px 29px 0px;
        }
      }
    }
  }
}

.Modal {
  &__heading {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 38px;
  }

  &__para {
    font-size: 16px;
    line-height: 24px;
    color: #000000;
  }

  &__parabold {
    font-size: 16px;
    line-height: 24px;
    color: #000000;
    font-weight: 500;
  }

  &__ButtonRow {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
}

.WhatsappDesktop {
  display: flex;
  padding: 8px 16px;
  gap: 8px;
  width: 228px;
  height: 40px;

  &__Image {
    position: relative;
  }

  &__Image2 {
    position: relative;
    top: -25px;
    left: -20px;
    width: 72px;
    height: 72px;
  }

  &__Para2 {
    position: relative;
    left: -45px;
  }
}

.ChannelForm {
  &__drawer{
    :global .ant-drawer-content-wrapper{
        width: 534px !important;
      }
    
}
  padding-bottom: 50px;

  &__formContainer {
    width: 100%;
    margin: 0 auto;

    :global {
      .ant-row {
        display: block;
        margin: 20px auto;
        padding: 10px 0;
      }

      label {
        font-style: normal;
        font-size: 16px;
        line-height: 24px;
        color: #616161;

        &::after {
          content: "" !important;
        }
      }

      Input {
        width: 100%;
        height: 40px;
        padding: 5px, 12px, 5px, 12px;
      }

      .ant-input-affix-wrapper {
        padding: 0 10px;
      }
    }

    &__error {
      color: red;
      height: 0;
    }
  }
}

.OTP {
  &__Margin {
    margin-bottom: 42px;
  }

  &__InputField {
    margin-bottom: 0px;
    color: #262626;
    font-size: 16px;
    line-height: 24px;
  }
}

.Success {
  &__HeaderContainer {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 16px;

    &__Image {
      width: 60px;
      height: 60px;
    }

    &__Button {
      margin-top: 28px;
    }
  }
}

.CreateChannel {
  &__para {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #595959;
    flex: none;
    order: 1;
    flex-grow: 0;
    margin-top: 36px;
    margin-bottom: 36px;
  }

  &__Row {
    display: flex;
    gap: 16px;
  }
}

.Cards {
  &__Header {
    display: flex;
    align-items: center;

    // img {
    //     width: 24px;
    //     height: 24px;
    // }
    p {
      margin-bottom: 0px;
      margin-left: 12px;
    }
  }

  &__title {
    width: 341px;
    height: 216px;
  }

  &__syncBtn {
    padding: 0 !important;
  }

  &__syncIcon {
    color: rgba(24, 144, 255, 1) !important;
    margin-left: 5px;
  }

  &__ReloadIcon {
    color: "#1890FF";
  }

  &__button {
    display: block;
  }

  &__editBtn {
    float: right;
    margin-left: 8px;
  }
}

.scroll {
  display: grid;
  /* auto auto is telling the browser to render two rows, if needed */
  grid-template-rows: auto auto;
  grid-auto-flow: column;
  overflow-x: scroll;
  grid-gap: 20px;
  padding: 20px;
}

.tabs {
  width: 740px;
  overflow-x: auto;
}

@media (max-width: 980px) {

  .ChannelForm {
    &__drawer{
      :global .ant-drawer-content-wrapper{
          width: auto !important;
        }
      
  }
}
  .Cards {
    &__title {
      width: 341px;
      height: 216px;
    }

    &__button {
      display: flex;
      justify-content: space-between;
    }

    &__editBtn {
      float: none;
    }
  }
}

.WhatsappModal {
  &__Icon {
    color: #1890ff;
  }

  &__ImageContainer {
    display: flex;
    width: 100%;
    margin-bottom: 16px;
  }

  &__ImageIcon {
    width: 60px;
    margin-left: auto;
    margin-right: auto;
  }

  &__Heading {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;

    /* identical to box height, or 133% */
    text-align: center;

    color: #000000;
  }

  &__Paragraph {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;

    /* or 150% */
    text-align: center;

    /* Gray / gray-8 */
    color: #595959;
  }

  &__ButtonContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 20px;
  }
}

.ResultInfo {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 9px 16px;
  gap: 11px;

  width: 100%;
  height: auto;

  /* Daybreak Blue / blue-1 */
  background: #e6f7ff;
  border-radius: 4px;

  p {
    text-align: left;
    margin-bottom: 0px;
  }
}