.ShowContactDrawer {
  &__drawer {
    display: flex;
    flex-direction: column;

    :global .ant-drawer-close {
      margin-left: auto !important;
    }

    :global .ant-drawer-header {
      border-bottom: none;
    }
    &__close {
      display: flex;
      justify-content: flex-end;
    }
    &__head {
      display: flex;
      flex-direction: column;
      border-top: 1px solid #f0f0f0;
      border-bottom: 1px solid #f0f0f0;
      padding: 31px 0px;

      &__img {
        margin-right: 10px;
      }

      &__right {
        display: flex;
        flex-direction: column;

        &__btn {
          h6 {
            color: #1890ff;
            cursor: pointer;
            font-size: 14px;
          }
        }
      }

      &__btncontainer {
        display: flex;
        margin-top: 20px;
        > div {
          margin-right: 20px;
          cursor: pointer;
        }
      }

      &__piccontainer {
        display: flex;
        align-items: center;

        span {
          font-family: "Inter";
          font-style: normal;
          font-weight: 500;
          font-size: 20px;
          line-height: 28px;
          /* identical to box height, or 140% */

          display: flex;
          align-items: center;

          /* Gray/gray-9 */

          color: #262626;
          margin-left: 16px;
        }
      }
    }

    &__details {
      padding: 31px 0px;

      &__item {
        display: flex;
        flex-direction: column;
        margin-bottom: 24px;

        :global .ant-avatar-sm {
          width: 16px;
          height: 16px;
          line-height: 16px;
          margin-right: 10px;
        }
        &__user {
          width: min-content;
          display: flex;
          align-items: center;
          // margin: auto 0;
          // margin-right: 10px;
        }

        &__wp {
          display: flex;
          align-items: center;
        }

        p {
          font-family: "Inter";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 22px;
          /* identical to box height, or 157% */

          /* Gray/gray-7 */

          color: #8c8c8c;
        }

        &__span {
          font-family: "Inter";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 22px;
          margin-top: 14px;
          /* or 157% */

          /* Gray/gray-9 */

          color: #262626;
        }
      }
    }
  }
}
