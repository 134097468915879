.AutomationIndividualCondition {
    &__Body {
        display: flex;
        flex-direction: column;
        padding: 16px;
        gap: 16px;
        width: 100%;
        min-height: 64px;

        &__FirstRow {
            display: flex;
            gap: 16px;
        }
    }

    &__Select {

        &__Condition {}

    }
}