.dashboard {
  gap: 20px;
  background-color: #f0f0f0;

  @media (max-width: 768px) {
    gap: 10px;
    padding: 10px;
  }
}

.topSection {
  display: grid;
  gap: 20px;
  padding: 20px;
  grid-template-rows: auto auto;
  grid-gap: 20px;
  width: 100%;

  @media (max-width: 760px) {
    padding: 10px;
    height: auto;
    display: grid;
    grid-template-columns: 1fr;


  }
  @media (min-width: 760px) and (max-width: 1280px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    padding: 36px 36px
  }

  @media (min-width: 1280px) and (max-width: 1366px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    padding: 36px 36px

  }

  @media (min-width: 1366px) and (max-width: 1820px) {
    display: grid;
    grid-template-columns: 1fr 1fr; 
    gap: 20px;
    padding: 36px 36px;
  }

  @media (min-width: 1820px) and (max-width: 2560px) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;
  }
}

.lowerSection {
  display: grid;
  grid-template-columns: 70% 30%; 
  gap: 20px;
  height: 50%;
  padding: 20px;
  grid-template-rows: auto auto;
  grid-gap: 20px;
  width: 100%;
  @media (max-width: 760px) {
    padding: 10px;
    height: auto;
    display: grid;
    grid-template-columns: 1fr;


  }
  @media (min-width: 760px) and (max-width: 1280px) {
    padding: 10px;
    height: auto;
    display: grid;
    grid-template-columns: 1fr;
  }

  @media (min-width: 1280px) and (max-width: 1366px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
  }

  @media (min-width: 1366px) and (max-width: 1820px) {
    display: grid;
    grid-template-columns: 1fr 1fr; 
    gap: 20px;
  }
  @media (min-width: 1820px) and (max-width: 2560px) {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;
  }


}
