.InterfaceOptionBody {
    display: flex;
    flex-direction: column;
    padding: 30px; 
  }
  
  .option {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px; 
  }

  .span {
    font-size: 14px;
  }
  
  /* Media Queries */
  @media (max-width: 1050px) {
    .InterfaceOptionBody {
    }
  }
  
  @media (max-width: 980px) {
    .InterfaceOptionBody {
    }
  }
  
  @media (max-width: 530px) {
    .InterfaceOptionBody {
    }
  }
  