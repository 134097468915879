.ImportContact {
    margin-left: 20px;
}

.ContactsPage {
    height: 100%;
    display: flex;

    &__container {
        // display: flex;
        width: 100%;

        &__customers {
            flex: 1;
            height: 100%;
            padding: 20px;
            display: flex;
            flex-direction: column;

            &__head {
                padding: 21px 0px;
                border-bottom: 1px solid #F0F0F0;

                h3 {
                    font-family: 'Inter';
                    font-style: normal;
                    font-weight: 600;
                    font-size: 20px;
                    line-height: 28px;
                }
            }

            &__body {}
        }

        &__contacts {
            flex: 3;
            display: flex;
            flex-direction: column;
            // padding: 20px;
            height: 100%;
            background: #FAFAFA;

            &__header {
                border-bottom: 1px solid #f0f0f0;
                display: flex;
                justify-content: space-between;
                padding-bottom: 30px;
                // position: fixed;
                // top: 38px;
                width: 100%;
                z-index: 123123123;
                background: white;
                padding-top: 15px;
                padding: 20px;

                &__left {
                    h3 {
                        font-family: 'Inter';
                        font-style: normal;
                        font-weight: 600;
                        font-size: 20px;
                        line-height: 28px;
                    }
                    &__container{
                        display: flex;
                        align-items: center;
                        &__icon{
                            cursor: pointer;
                            margin-bottom: 10.5px;
                            margin-right: 10px;
                            font-size: 16px;
                        }
                    }
                }

                &__right {
                    display: flex;
                    align-items: flex-end;
                    flex-direction: column;
                    justify-content: space-between;
                    // width: 265px;

                    &__filterbutton {
                        width: 100px;
                    }
                }

            }
            &__headermobile {
                display: none;
                border-bottom: 1px solid #f0f0f0;
                justify-content: space-between;
                padding-bottom: 30px;
                // position: fixed;
                // top: 38px;
                width: 100%;
                z-index: 123123123;
                background: white;
                padding-top: 15px;
                padding: 20px;

                &__left {
                    h3 {
                        font-family: 'Inter';
                        font-style: normal;
                        font-weight: 600;
                        font-size: 20px;
                        line-height: 28px;
                    }
                    &__container{
                        display: flex;
                        align-items: center;
                        &__icon{
                            cursor: pointer;
                            margin-bottom: 10.5px;
                            margin-right: 10px;
                            font-size: 16px;
                        }
                    }
                }

                &__right {
                    display: flex;
                    align-items: flex-end;
                    flex-direction: column;
                    justify-content: space-between;
                    // width: 265px;

                    &__filterbutton {
                        width: 100px;
                    }
                }

            }

            &__table {
                // margin-top: 100px;
            }
        }
    }

}


.DeleteModal {
    display: flex;
    flex-direction: column;

    &__Modal {
        max-width: 400px;
    }

    &__Body {
        display: flex;
        gap: 17.5px;

        &__Icon {
            color: #FF4D4F;
        }

        &__IconContainer {}

        &__ContentContainer {
            display: flex;
            flex-direction: column;
            text-align: left;

            h5 {
                font-family: 'Inter';
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 24px;
                color: #262626;
            text-align: left;
                
            }

            p {
                font-family: 'Inter';
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 22px;
                color: #262626;
            text-align: left !important;

            }
        }
    }

    &__Footer {
        display: flex;
        flex-direction: row-reverse;
        gap: 8px;
    }
}


@media (max-width: 767px){

    .ImportContact {
        margin-left: 0px;
    }

    .ContactsPage {
    
        &__container {

            &__contacts {
    
                &__header {
                    display: none;
                }
                &__headermobile {
                    display: flex;
                    border-bottom: 1px solid #f0f0f0;
                    justify-content: space-between;
                    padding-bottom: 30px;
                    // position: fixed;
                    // top: 38px;
                    width: 100%;
                    z-index: 123123123;
                    background: white;
                    padding-top: 15px;
                    padding: 20px;
    
                    &__left {
                        h3 {
                            font-family: 'Inter';
                            font-style: normal;
                            font-weight: 600;
                            font-size: 20px;
                            line-height: 28px;
                        }
                        &__container{
                            display: flex;
                            align-items: center;
                            &__icon{
                                cursor: pointer;
                                margin-bottom: 10.5px;
                                margin-right: 10px;
                                font-size: 16px;
                            }
                        }
                    }
    
                    &__right {
                        display: flex;
                        align-items: flex-end;
                        flex-direction: column;
                        justify-content: space-between;
                        // width: 265px;
    
                        &__filterbutton {
                            width: 100px;
                        }
                    }
    
                }
            }
        }
    
    }
        
}