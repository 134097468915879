.CreateEmailExtraction {
    width: 100%;

    &__ButtonContainer {
        display: flex;
        flex-direction: row-reverse;
        margin-left: auto;
        width: 100%;
        gap: 20px;
    }

    &__ErrorModalText {
        color: var(--gray-gray-9, #262626);
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 22px;
    }

    &__AttributeMapping {
        width: 100%;
        border-radius: 2px;
        border: 1px solid var(--gray-gray-4, #F0F0F0);
        margin-top: 50px;

        &__Header {
            display: flex;
            width: 100%;
            background: #F0F0F0;

            &__Column {
                width: 50%;
                height: 38px;
                color: var(--gray-gray-9, #262626);
                /* Semibold/14px | 22px */
                font-family: Inter;
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                line-height: 22px;
                align-items: center;
                padding: 8px;
            }
        }

        &__Row {
            display: flex;
            width: 100%;
            border-bottom: 1px solid var(--gray-gray-4, #F0F0F0);


            &__Column {
                width: 50%;
                height: 60px;
                padding: 8px;

                &__Name {
                    color: var(--gray-gray-9, #262626);
                    font-family: Inter;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 22px;
                    margin-bottom: 0px;
                }

                &__Value {
                    color: var(--gray-gray-8, #595959);
                    /* Regular/14px | 22px */
                    font-family: Inter;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 22px;
                }
            }
        }
    }

    &__inputfooter {
        color: var(--gray-gray-7, #8C8C8C);

        /* Medium/14px | 22px */
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 22px;
        /* 157.143% */
    }

    &__drawer {
        :global .ant-drawer-content-wrapper {
            width: 600px !important;
        }

        &__footer {
            display: flex;
            justify-content: space-between;
        }

    }

    &__autoforward {
        display: flex;
        flex-direction: column;
        padding-bottom: 20px;
        border-bottom: 1px solid #D9D9D9;

        &__footer {
            color: var(--gray-gray-8, #595959);

            /* Regular/14px | 22px */
            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px;

            span {
                color: var(--daybreak-blue-blue-7, #096DD9);

                /* Regular/14px | 22px */
                font-family: Inter;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 22px;
            }

            /* 157.143% */
        }

        &__successcontainer {
            display: flex;
            margin-bottom: 4px;

            &__text {
                margin-left: 4px;
                display: flex;
                flex-direction: column;

                h4 {
                    color: var(--gray-gray-9, #262626);

                    /* Medium/14px | 22px */
                    font-family: Inter;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 22px;
                    margin-bottom: 0px;
                    /* 157.143% */
                }

                span {
                    color: var(--gray-gray-7, #8C8C8C);

                    /* Regular/14px | 22px */
                    font-family: Inter;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 22px;
                    /* 157.143% */
                }
            }
        }
    }

    &__parsing {
        display: flex;
        flex-direction: column;
        padding: 16px;
        margin-top: 20px;
        border-radius: 8px;

        &__head {
            color: var(--gray-gray-9, #262626);

            /* Medium/14px | 22px */
            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 22px;
            /* 157.143% */
        }

        &__text {
            color: var(--gray-gray-8, #595959);

            /* Regular/14px | 22px */
            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px;
            /* 157.143% */
            margin: 10px 0px;
        }

        &__header {
            display: flex;
            justify-content: space-between;
        }

        &__body {
            display: flex;
            flex-direction: column;

            &__textarea {
                margin: 8px 0px;
            }

            &__forward {
                color: var(--gray-gray-8, #595959);

                /* Regular/14px | 22px */
                font-family: Inter;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 22px;
                margin: 16px 0px;

                span {
                    font-weight: bold;
                }

                /* 157.143% */
            }

            &__list {
                color: var(--gray-gray-8, #595959);

                /* Medium/14px | 22px */
                font-family: Inter;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 22px;
                /* 157.143% */
            }

            &__specialtext {
                color: var(--gray-gray-8, #595959);

                /* Medium/14px | 22px */
                font-family: Inter;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 22px;
                /* 157.143% */
            }

            &__textarea {
                margin: 8px 0px;
            }

            &__instructioncontainer {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                margin-top: 8px;
                margin-bottom: 16px;

                :global .ant-btn[disabled] {
                    span {
                        color: #BFBFBF !important;
                    }

                    color: #BFBFBF !important;
                }


                span {
                    color: var(--daybreak-blue-blue-7, #096DD9) !important;
                    cursor: pointer;
                    /* Regular/14px | 22px */
                    font-family: Inter;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 22px;
                    /* 157.143% */
                }
            }

            &__extractcontainer {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                margin-top: 20px;
            }
        }

        background: var(--gray-gray-2, #FAFAFA);



        &__footer {
            display: flex;
            justify-content: space-between;

            button {
                margin-left: auto;
                border: 1px solid #096DD9;

                span {
                    color: #096DD9;
                }
            }
        }
    }

    &__Header {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: #000000;
    }

    &__SubHeader {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        color: #595959;
        margin-top: 8px;
    }

}

.ChannelForm {
    // padding-bottom: 16px;
    padding-top: 20px;

    &__ButtonContainer {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
}

@media (max-width: 980px) {
    .CreateEmailExtraction {
        width: 100%;

        &__drawer {
            :global .ant-drawer-content-wrapper {
                width: auto !important;
            }

        }

        &__Header {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            color: #000000;
        }

        &__SubHeader {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
            color: #595959;
            margin-top: 8px;
        }
    }

    .ChannelForm {
        padding-bottom: 16px;
        padding-top: 20px;

        &__ButtonContainer {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
        }
    }
}