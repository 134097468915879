.statistics {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  flex: 1;
  @media (min-width: 760px) and (max-width: 1280px)  {
    grid-column-start: auto;
    grid-column-end: span 2;
    
  }
  
}
.statItem  {
  display: flex;
  margin-bottom: 20px;
  justify-content: space-between;
  font-size: smaller;

  @media (max-width: 768px) {
    margin-bottom: 10px;
  }
}
.chartHeader{
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #595959;

}
