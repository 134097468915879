.InputContainer {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 8px;
  }

  .InputFieldContainer {
    display: flex;
    flex-direction: column;
  }
  
  .InputField {
    padding: 4px;
    border: 1px solid lightgrey;
    border-radius: 4px;
  }

  .InputFieldError {
    padding: 4px;
    border: 0.5px solid rgb(255, 55, 0) !important;
    border-radius: 4px;
  }

  .InputField:focus {
    outline: none;
    border-color: dodgerblue;
    box-shadow: 0 0 3px rgba(30, 144, 255, 0.3);
  }
  
  .ErrorMessage{
    color: red;
  }