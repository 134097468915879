.FilterContactDrawer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  // width: 500px;
  :global .ant-drawer-content-wrapper{
    width: 500px !important;
  }

  :global .ant-drawer-body{
    display:flex;
    flex-direction:column;
  }
  
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    &__left {
      display: flex;
      align-items: center;
      &__arrow {
        margin-right: 8px;
        cursor: pointer;
      }
    }
  }
  &__body {
    margin: 10px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    max-height: 87%;
    overflow-y: scroll;
    overflow-x: hidden;
  }
  &__buttons {
    display: flex;
    margin-top: auto;
    justify-content: space-between;
    // position: absolute;
    bottom: 5px;
    right: 5px;
    left: 5px;
  }
}

@media (max-width:768px){
  .FilterContactDrawer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    :global .ant-drawer-content-wrapper{
      width: auto !important;
    }
  
    :global .ant-drawer-body{
      display:flex;
      flex-direction:column;
    }
    
    &__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      &__left {
        display: flex;
        align-items: center;
        &__arrow {
          margin-right: 8px;
          cursor: pointer;
        }
      }
    }
    &__body {
      margin: 10px 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      max-height: 87%;
      overflow-y: scroll;
      overflow-x: hidden;
    }
    &__buttons {
      display: flex;
      margin-top: auto;
      justify-content: space-between;
      // position: absolute;
      bottom: 5px;
      right: 5px;
      left: 5px;
    }
  }
  
}
