.dashboard {
    display: flex;
    flex-direction: column;

}
.widgets {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    margin-top: 10px;
    // background-color: aqua;
}

.reportsHeader{
    display: flex;
    margin-top: 30px;
}
.statistic{
    flex: 2;
    padding: 10px;
    margin: 10px;
}
.mainTable {
    flex: 1;
}
.ant-table table { font-size: 10px; }

:global {
    .ant-table-row-expand-icon{
       display: none;
    }
   }

   
